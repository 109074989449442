import React from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

interface Props {
  isSnackbarOpen: boolean;
  setIsSnackbarOpenCallback: (isOpen: boolean) => void;
  severity: 'warning' | 'error';
  message: JSX.Element;
  autoHideDuration?: number;
}

const defaultProps = {
  autoHideDuration: null
};

export default function AlertSnackbar(props: Props): React.ReactElement {
  const {
    isSnackbarOpen,
    setIsSnackbarOpenCallback,
    severity,
    message,
    autoHideDuration = defaultProps.autoHideDuration
  } = props;

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setIsSnackbarOpenCallback(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={isSnackbarOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onCloseSnackbar}
      autoHideDuration={autoHideDuration}
    >
      <Alert severity={severity} action={action} onClose={() => setIsSnackbarOpenCallback(false)}>
        {message}
      </Alert>
    </Snackbar>
  );

  function onCloseSnackbar(_: React.SyntheticEvent<any>, reason: SnackbarCloseReason) {
    if (reason !== 'clickaway') {
      setIsSnackbarOpenCallback(false);
    }
  }
}
