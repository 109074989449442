import React from 'react';
import { isEmpty } from 'components/utils/equality';
import { FormErrors, StudentAudienceProps } from 'components/models/FormProps';
import { SaveStatus } from '../ContentContainer';
import NoAudienceSelected from '../NoAudienceSelected';
import AutosaveContainer from './AutosaveContainer';

interface Props {
  viewableAudience: StudentAudienceProps;
  personalisedFieldsTableUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  csrfToken: string;
  setCsrfToken: (csrfToken: string) => void;
  metadataVersion: number;
  setMetadataVersion: (metadataVersion: number) => void;
  setIsFormVersionSnackbarOpen: (isFormVersionSnackbarOpen: boolean) => void;
  personalisedFieldsErrors: FormErrors;
  setPersonalisedFieldsErrors: (personalisedFieldsErrors: FormErrors) => void;
  viewOnly: boolean;
  saveStatus: SaveStatus;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
}

export default function PersonaliseAudienceContainer(props: Props) {
  const { viewableAudience, personalisedFieldsTableUrl, downloadPersonalisedFieldsUrl, uploadPersonalisedFieldsUrl,
    csrfToken, setCsrfToken, metadataVersion, setMetadataVersion, setIsFormVersionSnackbarOpen,
    personalisedFieldsErrors, setPersonalisedFieldsErrors } = props;

  return renderContent();

  function renderContent() {
    if (isEmpty(viewableAudience.entities)) {
      return <NoAudienceSelected />;
    }

    return (
      <AutosaveContainer
        personalisedFieldsTableUrl={personalisedFieldsTableUrl}
        downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
        uploadPersonalisedFieldsUrl={uploadPersonalisedFieldsUrl}
        csrfToken={csrfToken}
        setCsrfToken={setCsrfToken}
        metadataVersion={metadataVersion}
        setMetadataVersion={setMetadataVersion}
        setIsFormVersionSnackbarOpen={setIsFormVersionSnackbarOpen}
        personalisedFieldsErrors={personalisedFieldsErrors}
        setPersonalisedFieldsErrors={setPersonalisedFieldsErrors}
        {...props}
      />
    );
  }
}
