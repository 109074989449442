import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import { isEmpty, isNotEmpty, isNonEmptyObject } from '../../../utils/equality';
import { BaseQuestion, newBaseQuestion, QuestionTypePrefix, renderQuestionText, extractErrorsIfPresent } from '../BaseQuestion';
import { ElementComponentProps, isElementComponentPropsEqual, renderHeader } from '../../BaseElement';

export const LongTextQuestionType = `${QuestionTypePrefix}::LongText`;

export interface LongTextQuestion extends BaseQuestion {
  char_limit: number;
}

export function newLongTextQuestion(element?: LongTextQuestion) {
  const { char_limit } = element || {};

  return {
    ...newBaseQuestion(element),
    type: LongTextQuestionType,
    char_limit: char_limit || 0
  };
}

export const LongTextQuestionComponent = React.memo(longTextQuestionComponent, isElementComponentPropsEqual);
function longTextQuestionComponent(props: ElementComponentProps<LongTextQuestion>) {
  const { element, numErrors, answerMode, qnNum } = props;

  const hasAnswerErrors = isNonEmptyObject(answerMode?.answer?.errors);

  return (
    <Box className={classNames('form-element', 'form-long-text-question', { 'form-element-error': hasAnswerErrors })}>
      {renderHeader(`QUESTION ${qnNum} - FREE TEXT`, element.required, numErrors, isNotEmpty(element.display_conditions_container.display_conditions), isEmpty(answerMode))}
      {renderQuestionText(element.text)}

      <TextField
        variant="standard"
        multiline
        fullWidth
        value={answerMode?.answer?.values[0] || ''}
        onChange={onChangeAnswer}
      />
      <Stack direction="row" justifyContent="space-between">
        <FormHelperText className="form-helper-text" error>{extractErrorsIfPresent(answerMode?.answer?.errors)}</FormHelperText>
        {element.char_limit > 0 && (
          <Typography className="char-count">
            {answerMode?.answer?.values[0]?.length || 0}/{element.char_limit}
          </Typography>
        )}
      </Stack>
    </Box>
  );

  function onChangeAnswer(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (isEmpty(answerMode)) return;

    answerMode.onChangeAnswer({ ...answerMode.answer, values: [event.target.value] });
  }
}
