/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { AudienceStage } from './Content';
import { SaveStatus } from './ContentContainer';
import { AudienceProps, AudienceType, FormErrors, newStaffAudienceProps, newStudentAudienceProps } from '../../../../models/FormProps';
import { isEmpty, isNotEmpty } from '../../../../utils/equality';

interface Props {
  formsUrl: string;
  activeStep: AudienceStage;
  saveStatus: SaveStatus;
  viewableAudience: AudienceProps;
  setActiveStepCallback: (stage: AudienceStage) => void;
  updateViewableAudienceCallback: (viewableAudience: AudienceProps) => void;
  clearPersonalisedFieldsUrl: string;
  csrfToken: string;
  setCsrfToken: (csrfToken: string) => void;
  metadataVersion: number;
  setMetadataVersion: (metadataVersion: number) => void;
  setIsFormVersionSnackbarOpen: (isFormVersionSnackbarOpen: boolean) => void;
  setPersonalisedFieldsErrors: (personalisedFieldsErrors: FormErrors) => void;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
  formErrors: FormErrors;
  viewOnly: boolean;
}

export default function BreadcrumbHeader(props: Props): React.ReactElement {
  const { formsUrl, saveStatus, activeStep, viewableAudience, setActiveStepCallback, updateViewableAudienceCallback,
    clearPersonalisedFieldsUrl, csrfToken, setCsrfToken, metadataVersion, setMetadataVersion,
    setIsFormVersionSnackbarOpen, setPersonalisedFieldsErrors, setSaveStatusCallback, formErrors, viewOnly } = props;

  return renderContent();

  function renderContent() {
    if (activeStep === AudienceStage.SelectAudienceType || viewableAudience.type === AudienceType.PUBLIC) {
      return (
        <header className="manage-forms-edit-page-content-header">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link underline="hover" color="inherit" href={formsUrl}>
              Home
            </Link>
            <Typography color="#0054C8">Select Audience Type</Typography>
          </Breadcrumbs>

          {renderSaveStatus()}
        </header>
      );
    } else if (activeStep === AudienceStage.SelectedTargetedOrUntargetedAudience) {
      return (
        <header className="manage-forms-edit-page-content-header">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link underline="hover" color="inherit" href={formsUrl}>
              Home
            </Link>
            <Link underline="hover" color="inherit" onClick={() => setActiveStepCallback(AudienceStage.SelectAudienceType)}>
              Select Audience Type
            </Link>
            <Typography color="#0054C8">Access Type</Typography>
          </Breadcrumbs>

          {renderSaveStatus()}
        </header>
      );
    } else if (activeStep === AudienceStage.SelectIndividualsOrGroups) {
      return (
        <header className="manage-forms-edit-page-content-header">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link underline="hover" color="inherit" href={formsUrl}>
              Home
            </Link>
            <Link underline="hover" color="inherit" onClick={() => setActiveStepCallback(AudienceStage.SelectAudienceType)}>
              Select Audience Type
            </Link>
            <Link underline="hover" color="inherit" onClick={() => setActiveStepCallback(AudienceStage.SelectedTargetedOrUntargetedAudience)}>
              Access Type
            </Link>
            <Typography color="#0054C8">{viewableAudience.type === AudienceType.STAFF ? 'Staff' : 'Students'}</Typography>
          </Breadcrumbs>

          {renderSaveStatus()}

          <Button
            variant="outlined"
            onClick={removeAudienceEntities}
            disabled={viewOnly}
          >
            CLEAR ALL
          </Button>
        </header>
      );
    } else if (activeStep === AudienceStage.PersonaliseFields) {
      return (
        <header className="manage-forms-edit-page-content-header">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link underline="hover" color="inherit" href={formsUrl}>
              Home
            </Link>
            <Link underline="hover" color="inherit" onClick={() => setActiveStepCallback(AudienceStage.SelectAudienceType)}>
              Select Audience Type
            </Link>
            <Link underline="hover" color="inherit" onClick={() => setActiveStepCallback(AudienceStage.SelectedTargetedOrUntargetedAudience)}>
              Access Type
            </Link>
            <Link underline="hover" color="inherit" onClick={() => setActiveStepCallback(AudienceStage.SelectIndividualsOrGroups)}>
              {viewableAudience.type === AudienceType.STAFF ? 'Staff' : 'Students'}
            </Link>
          </Breadcrumbs>

          {renderSaveStatus()}
          <Button
            variant="outlined"
            onClick={removeAudienceMetadata}
            disabled={viewOnly}
          >
            CLEAR ALL
          </Button>
        </header>
      );
    }
  }

  function renderSaveStatus() {
    if (viewOnly) return <Alert className="save-status" severity="warning">View Only</Alert>;

    switch (saveStatus) {
      case SaveStatus.Saved: {
        return <Alert className="save-status" severity="success">Saved</Alert>;
      }
      case SaveStatus.Saving: {
        return <Alert className="save-status" severity="info">Saving...</Alert>;
      }
      case SaveStatus.Error: {
        const defaultMsg = 'Please fix error(s) to save';
        return <Alert className="save-status" severity="error">{isEmpty(formErrors?.version) ? defaultMsg : formErrors.version}</Alert>;
      }
      default: {
        return null;
      }
    }
  }

  function removeAudienceEntities() {
    if (!confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;

    switch (viewableAudience.type) {
      case AudienceType.STAFF: {
        updateViewableAudienceCallback(newStaffAudienceProps());
        break;
      }
      case AudienceType.STUDENT: {
        const studentAudienceProps = newStudentAudienceProps({ year: viewableAudience.year });
        updateViewableAudienceCallback(studentAudienceProps);
        break;
      }
      default:
        // Do nothing
    }
  }

  function removeAudienceMetadata() {
    if (!confirm('This will delete all currently uploaded personalised fields. Click to proceed.')) return;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        lock_version: metadataVersion
      })
    };

    setSaveStatusCallback(SaveStatus.Saving);
    fetch(clearPersonalisedFieldsUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setMetadataVersion(data.version);
        }
        if (isNotEmpty(data.errors?.version)) {
          setIsFormVersionSnackbarOpen(true);
        }
        setCsrfToken(csrfToken);
        setSaveStatusCallback(data.success ? SaveStatus.Saved : SaveStatus.Error);
        setPersonalisedFieldsErrors(data.errors);
      })
      .catch(() => setSaveStatusCallback(SaveStatus.Error));
  }
}
