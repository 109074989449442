import React, { FC } from 'react';
import { ThemeProvider } from '@mui/material';
import defaultTheme from './utils/theme';
import EmotionProvider from './EmotionProvider';
import AntiPhishingDialogProvider from './AntiPhishingDialogProvider';

interface Props {
  children: React.ReactNode;
}

const AppProvider: FC<Props> = ({ children }) => (
  <EmotionProvider>
    <ThemeProvider theme={defaultTheme}>
      <AntiPhishingDialogProvider>
        {children}
      </AntiPhishingDialogProvider>
    </ThemeProvider>
  </EmotionProvider>
);

export default AppProvider;
