import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { CollaboratorPermission } from '../../../../../models/FormCollaborator';
import { Staff, Collaborator } from '../Page';
import { naturalStringSort } from '../../../../../utils/sort';
import DebouncedSearchbar from '../../common/DebouncedSearchbar';
import CollaboratorInfoButton from './CollaboratorInfoButton';
import SEARCH_RESULTS_NOT_PRESENT from './constants';

interface ContentProps {
  creatorID: number;
  schoolID: number | null;
  schoolName: string | null;
  staffs: Array<Staff>;
  onSearch: (searchString: string, shouldFilterBySchool: boolean) => void;
  searchResults: Array<Staff> | typeof SEARCH_RESULTS_NOT_PRESENT;
  existingCollaboratorIDs: Array<number>;
  onAddCollaborator: (collaborator: Collaborator) => void;
  viewOnly: boolean;
}

enum SearchFilters {
  AllSchools = 'All Schools',
  AssignedSchool = 'My school'
}

export default function Content(props: ContentProps): React.ReactElement {
  const {
    creatorID,
    schoolID,
    schoolName,
    staffs,
    onSearch,
    searchResults,
    existingCollaboratorIDs,
    viewOnly,
    onAddCollaborator
  } = props;
  const [searchStringState, setSearchStringState] = useState('');
  const [searchFilter, setSearchFilter] = React.useState(schoolID !== null ? SearchFilters.AssignedSchool : SearchFilters.AllSchools);

  useEffect(() => {
    const shouldFilterBySchoolState = searchFilter === SearchFilters.AssignedSchool;
    onSearch(searchStringState, shouldFilterBySchoolState);
  }, [searchStringState, searchFilter]);

  return (
    <Stack spacing={3} component={Paper} className="search">
      {renderSearchTextField()}
      {searchResults === SEARCH_RESULTS_NOT_PRESENT && searchFilter === SearchFilters.AssignedSchool ? renderDefaultStaffList(staffs) : renderSearchResults(searchResults)}
    </Stack>
  );

  function renderSearchTextField() {
    return (
      <Grid container spacing={1}>
        <Grid item xs>
          <DebouncedSearchbar
            placeholder="Search and add collaborators"
            onSearch={setSearchStringState}
            viewOnly={viewOnly}
          />
        </Grid>
        {
          schoolID !== null && (
            <Grid item xs={4}>

              <Select
                value={searchFilter}
                onChange={handleFilterToggle}
                size="small"
                fullWidth
              >
                <MenuItem value={SearchFilters.AllSchools}>{SearchFilters.AllSchools}</MenuItem>
                <MenuItem value={SearchFilters.AssignedSchool}>{SearchFilters.AssignedSchool}</MenuItem>
              </Select>
            </Grid>

          )
        }
      </Grid>
    );
  }

  function renderDefaultStaffList(staffList: Array<Staff>) {
    const filteredStaff = staffList.filter(s => !existingCollaboratorIDs.includes(s.roster_id) && s.roster_id !== creatorID);

    return (
      filteredStaff.length !== 0 && (
        <Stack spacing={2}>
          <Typography variant="caption">Showing all <strong>{filteredStaff.length} staffs</strong> in <strong>{schoolName}</strong></Typography>
          {renderStaffList(filteredStaff.sort((a, b) => naturalStringSort(a.name, b.name)))}
        </Stack>
      )
    );
  }

  function renderSearchResults(results: Array<Staff> | typeof SEARCH_RESULTS_NOT_PRESENT) {
    if (results === SEARCH_RESULTS_NOT_PRESENT) return <></>;

    const filteredSearchResults = results.filter(s => !existingCollaboratorIDs.includes(s.roster_id) && s.roster_id !== creatorID);

    if (filteredSearchResults.length === 0) {
      return (
        <Stack paddingY={3} alignItems="center">
          <Typography variant="h6">
            No results found
          </Typography>
          <Typography variant="body1" className="no-results-found-body">
            Please try another search term.
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={2}>
          <Typography variant="caption">
            {
              filteredSearchResults.length === 10 ? (
                <>
                  Showing <strong>10 most relevant results</strong>
                </>
              )
                : (
                  <>
                    {`${filteredSearchResults.length} staffs`}
                  </>
                )
            }
          </Typography>
          {renderStaffList(filteredSearchResults)}
        </Stack>
      );
    }
  }

  function renderStaffList(staffList: Array<Staff>) {
    return (
      <Stack spacing={1}>
        {
          staffList.map(staff => (
            <CollaboratorInfoButton
              key={staff.roster_id}
              staff={staff}
              onClickCallback={() => onSelectStaff(staff)}
              disabled={viewOnly}
            />
          ))
        }
      </Stack>
    );
  }

  function onSelectStaff(staff :Staff) {
    onAddCollaborator({
      ...staff,
      permission: CollaboratorPermission.View
    });
  }

  function handleFilterToggle(event: SelectChangeEvent) {
    setSearchFilter(event.target.value === SearchFilters.AssignedSchool ? SearchFilters.AssignedSchool : SearchFilters.AllSchools);
  }
}
