import TextField from '@mui/material/TextField';
import React from 'react';
import { isNotEmpty } from '../../../utils/equality';
import { renderHeader, ElementEditorProps } from '../../BaseElementEditor';
import { DisplayConditionEditor } from '../../display_condition/DisplayConditionEditor';
import { renderAnswerSettingLabel, renderQuestionText, renderRequiredSetting } from '../BaseQuestionEditor';
import { RankingQuestion, RankingQuestionOption } from './RankingQuestion';
import { OptionsEditor } from './option/OptionsEditor';

const MAX_OPTIONS = 20;

export function RankingQuestionEditor(props: ElementEditorProps<RankingQuestion>) {
  const { element, errors, viewOnly, onUpdate, onClone, onDelete, metadata } = props;

  return (
    <article className="element-settings-element element-settings-ranking-question">
      {renderHeader(element, 'Ranking', viewOnly, onClone, onDelete)}

      <main className="element-content">
        {renderAnswerSettingLabel()}
        {renderRequiredSetting(element, viewOnly, onUpdate)}

        <TextField
          label="OPTIONS TO RANK: "
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          fullWidth
          value={element.num_ranks}
          onChange={onNumRanksChanged}
          error={isNotEmpty(errors?.num_ranks)}
          helperText={isNotEmpty(errors?.num_ranks) && errors.num_ranks[0]}
          disabled={viewOnly}
        />

        {renderQuestionText(element, errors, viewOnly, onUpdate, metadata.headers)}

        <OptionsEditor
          options={element.options}
          onOptionsChangeCallback={onOptionsChange}
          errors={errors?.options}
          viewOnly={viewOnly}
        />

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function onNumRanksChanged(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const numOptions = Number(event.target.value);
    onUpdate({ ...element, num_ranks: isNaN(numOptions) ? 0 : Math.min(numOptions, MAX_OPTIONS) });
  }

  function onOptionsChange(newOptions: Array<RankingQuestionOption>) {
    onUpdate({ ...element, options: newOptions });
  }
}
