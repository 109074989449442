/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import QRCode from 'qrcode';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getCsrfToken } from 'components/utils/csrf';
import { PublishStatusProps } from 'components/models/FormProps';

interface ShareModalProps {
  formName: string;
  formUrl: string;
  createAnnouncementOnPgUrl: string;
  publishStatus: PublishStatusProps;
  enableEmailCopyOfResponse: boolean;
  onChangePublishStatus: (status: PublishStatusProps) => void;
  onChangeEnableEmailCopyOfResponse: (enableEmailCopyOfResponse: boolean) => void;
  onCloseModal: () => void;
  viewOnly: boolean;
  isShareableToPG: boolean;
}

export function ShareModal(props: ShareModalProps) {
  const { formName, formUrl, createAnnouncementOnPgUrl, publishStatus, enableEmailCopyOfResponse, onChangePublishStatus, onChangeEnableEmailCopyOfResponse, onCloseModal, viewOnly, isShareableToPG } = props;

  const [qrCode, setQRCode] = useState(null);
  const [isSendingToPG, setIsSendingToPG] = useState(false);
  const [pgErrorMessage, setPGErrorMessage] = useState('');
  const qrCodeRef = useRef<HTMLAnchorElement>();

  useEffect(() => {
    QRCode.toDataURL(formUrl, { width: 500, scale: 8 }, (err, url) => {
      if (url) setQRCode(url);
    });
  }, []);

  return (
    <Dialog className="share-form-modal" open onClose={onCloseModal}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="center">
          <Stack>
            <Typography>Unpublish</Typography>
            <Typography variant="caption">Stop responses</Typography>
          </Stack>
          <Switch checked={publishStatus === PublishStatusProps.Published} onChange={onChangePublishStatusSwitch} disabled={viewOnly} />
          <Stack>
            <Typography>Publish</Typography>
            <Typography variant="caption">Collect responses</Typography>
          </Stack>
        </Stack>

        <Divider className="divider" />

        <FormGroup>
          <FormControlLabel control={<Checkbox checked={!enableEmailCopyOfResponse} onChange={onChangeEnableEmailCopyOfResponseCheckbox} />} label="Do not let respondents email a copy of responses to themselves" />
        </FormGroup>

        <Stack marginTop="24px">
          <Typography variant="h6">Form Link</Typography>
          <Typography marginTop="8px" marginBottom="4px">Share form link or QR code to start collecting responses from your audience</Typography>

          <Stack direction="row" alignItems="center">
            <TextField fullWidth size="small" value={formUrl} />
            <Button className="primary-button" variant="contained" size="large" sx={{ marginLeft: '15px', marginRight: '5px' }} onClick={onClickCopy}>COPY</Button>
            {qrCode && (
              <a ref={qrCodeRef} href={qrCode} download={`${formName} QR.png`}>
                <img alt="QR code for this form" src={qrCode} width="50" />
              </a>
            )}
          </Stack>
        </Stack>

        {isShareableToPG && renderShareOnPG()}
      </DialogContent>
    </Dialog>
  );

  function onChangePublishStatusSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onChangePublishStatus(checked ? PublishStatusProps.Published : PublishStatusProps.Unpublished);
  }

  function onChangeEnableEmailCopyOfResponseCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    onChangeEnableEmailCopyOfResponse(!event.target.checked);
  }

  function onClickCopy() {
    window.navigator.clipboard.writeText(formUrl);
  }

  function renderShareOnPG() {
    return (
      <>
        <Typography marginTop="16px" marginBottom="4px">Share as an announcement on Parents Gateway</Typography>
        <Button
          className="secondary-button"
          variant="outlined"
          size="large"
          disabled={isSendingToPG}
          endIcon={isSendingToPG ? <CircularProgress color="inherit" size={20} /> : <OpenInNewIcon />}
          onClick={shareOnPg}
        >
          SHARE ON PARENTS GATEWAY
        </Button>
        {pgErrorMessage && <FormHelperText error>{pgErrorMessage}</FormHelperText>}
      </>
    );
  }

  function shareOnPg() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      }
    };

    setIsSendingToPG(true);
    setPGErrorMessage('');

    return fetch(createAnnouncementOnPgUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.link) {
          window.open(data.link);
        } else {
          setPGErrorMessage(data.error);
        }
      })
      .finally(() => setIsSendingToPG(false));
  }
}
