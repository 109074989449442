import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { isEmpty } from '../../../../../../utils/equality';
import NoRosterContent from '../../NoRosterContent';
import {
  AudienceType,
  isEntityEmpty,
  newStaffAudienceProps,
  School,
  Staff,
  StaffAudienceProps
} from '../../../../../../models/FormProps';
import StaffAudienceSelector from './selectors/StaffAudienceSelector';
import SelectedStaffAudience from './selected_staff/SelectedStaffAudience';
import { AudienceStage } from '../../Content';

interface Props {
  school: School;
  viewableAudience: StaffAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StaffAudienceProps) => void;
  setMaxStepCallback: (stage: AudienceStage) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getSchoolsUrl: string;
  getStaffRosterUrl: string;
}

export default function StaffSelectorContent(props: Props): React.ReactElement {
  const { viewableAudience, school, isHQUser, viewOnly, getSchoolsUrl, getStaffRosterUrl, updateViewableAudienceCallback, setMaxStepCallback } = props;
  const [schools, setSchools] = useState<Array<School>>([]);

  useEffect(() => {
    if (isHQUser) getSchools();
  }, []);

  return (isEntityEmpty(school) && !isHQUser) ? <NoRosterContent /> : renderContent();

  function renderContent() {
    if (isEntityEmpty(school) && isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={5} className="audience-editor-audience-selector-container">
          <StaffAudienceSelector
            school={school}
            schools={schools}
            viewableAudience={viewableAudience}
            addViewableEntitiesCallback={addViewableEntitiesCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
            getStaffRosterUrl={getStaffRosterUrl}
          />
        </Grid>
        <Grid item xs={7} className="audience-editor-audience-selector-container">
          <SelectedStaffAudience
            schools={schools}
            viewableAudience={viewableAudience}
            removeViewableEntitiesCallback={removeViewableEntitiesCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
          />
        </Grid>
      </Grid>
    );
  }

  function addViewableEntitiesCallback(viewableEntities: Array<School | Staff>) {
    const entitiesToAdd = viewableEntities
      .filter(entityToAdd => !viewableAudience.entities.some(entity => entity.entity_id === entityToAdd.entity_id && entity.entity_type === entityToAdd.entity_type));

    const entities = [...viewableAudience.entities, ...entitiesToAdd];
    updateViewableAudienceCallback(newStaffAudienceProps(entities));
    setMaxStepCallback(AudienceStage.PersonaliseFields);
  }

  function removeViewableEntitiesCallback(viewableEntitiesToRemove: Array<School | Staff>) {
    const entities = viewableAudience.entities
      .filter(entity => !viewableEntitiesToRemove
        .some(entityToRemove => entity.entity_id === entityToRemove.entity_id && entity.entity_type === entityToRemove.entity_type));

    if (isEmpty(entities)) {
      setMaxStepCallback(AudienceStage.SelectIndividualsOrGroups);
    }

    updateViewableAudienceCallback(newStaffAudienceProps(entities));
  }

  function getSchools() {
    const url = new URL(getSchoolsUrl);
    url.search = new URLSearchParams({ type: AudienceType.STAFF }).toString();

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setSchools(data.schools);
        }
      });
  }
}
