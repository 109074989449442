import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { isEmpty } from 'components/utils/equality';
import { groupBy } from 'components/utils/group_by';
import { LevelCodeToName } from 'components/utils/levels';
import {
  Clazz,
  Level,
  School,
  Student,
  StudentRoster,
  StudentAudienceProps
} from 'components/models/FormProps';

interface Props {
  studentRosters: Array<StudentRoster>;
  viewableAudience: StudentAudienceProps;
  addViewableEntityCallback: (viewableEntity: School | Level | Clazz | Student) => void;
  setSchoolIDCallback: (schoolID: number) => void;
  viewOnly: boolean;
  isHQUser: boolean;
}

export default function AudienceGroupSelector(props: Props): React.ReactElement {
  const { studentRosters, viewableAudience, addViewableEntityCallback, setSchoolIDCallback, viewOnly, isHQUser } = props;
  const roster = studentRosters.find(r => r.year === viewableAudience.year);
  const { school } = roster;

  return (
    <>
      <Box>
        {isHQUser && (
          <Button
            className="school-back-button"
            size="small"
            disableRipple
            disableFocusRipple
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setSchoolIDCallback(null)}
          >
            Back
          </Button>
        )}
        <Typography className="school-title">{school.name}</Typography>
      </Box>
      {renderStudentAudience()}
    </>
  );

  function renderStudentAudience() {
    const classesByLevel: { [key: string]: Array<Clazz> } = groupBy(roster.clazzes, 'level_id');

    return (
      <>
        <Box>
          <Typography>Default Groups</Typography>
          <br />
          <Button
            variant="outlined"
            disabled
            size="small"
          >
            All Students
          </Button>
        </Box>

        <Grid container rowSpacing={2} className="audience-groups-grid">
          {
            roster.levels.sort((a, b) => a.code - b.code).map(level => (
              <React.Fragment key={level.entity_id}>
                <Grid item container alignItems="center" xs={12} spacing={2}>
                  <Grid item>
                    <Typography>{LevelCodeToName[level.code]}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled
                      size="small"
                    >
                      All
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" xs={12} spacing={1}>
                  {
                    classesByLevel[level.entity_id]
                      ?.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                      .map(clazz => (
                        <Grid key={clazz.entity_id} item xs={4}>
                          <Button
                            variant="outlined"
                            onClick={() => handleAddAudienceEntity(clazz)}
                            disabled={!classButtonEnabled(clazz.entity_id) || viewOnly}
                            size="small"
                            sx={{ minWidth: '100%' }}
                          >
                            {clazz.name}
                          </Button>
                        </Grid>
                      ))
                  }
                </Grid>
              </React.Fragment>
            ))
          }
        </Grid>
      </>
    );
  }

  function classButtonEnabled(clazz_id: number): boolean {
    if (isEmpty(viewableAudience.entities)) return true;

    return !!viewableAudience.entities.find(entity => entity.entity_id === clazz_id);
  }

  function handleAddAudienceEntity(viewableEntity: School | Level | Clazz | Student) {
    addViewableEntityCallback(viewableEntity);
  }
}
