import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Clazz,
  Level
} from '../../../../../../../models/FormProps';
import DeleteAudienceDialog from '../../common/DeleteAudienceDialog';

interface Props {
  removeViewableEntitiesCallback: (viewableEntities: Array<Level | Clazz>) => void;
  viewOnly: boolean;
  tableHeader: string;
  pluralEntityName: string;
  viewableEntities: Array<Level | Clazz>;
  getDisplayName:(entity: Level | Clazz) => string;
}

export default function AudienceTable(props: Props): React.ReactElement {
  const { tableHeader, pluralEntityName, viewableEntities, removeViewableEntitiesCallback, viewOnly, getDisplayName } = props;

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label={`Selected ${pluralEntityName}`}>
        <TableHead className="table-header">
          <TableRow>
            <TableCell>{`${tableHeader} (${viewableEntities.length})`}</TableCell>
            <TableCell align="right">
              <DeleteAudienceDialog
                icon={<DeleteIcon fontSize="small" htmlColor="white" />}
                viewOnly={viewOnly}
                removeAudienceCallback={() => removeViewableEntitiesCallback(viewableEntities)}
              >
                <DialogTitle>{`Remove all selected ${pluralEntityName}?`}</DialogTitle>
                <DialogContent>
                  <Typography>
                    {`This will deselect the following ${pluralEntityName}:`}
                  </Typography>
                  {
                    viewableEntities.map(entity => <Typography fontWeight="bold" key={entity.entity_id}>{getDisplayName(entity)}</Typography>)
                  }
                </DialogContent>
              </DeleteAudienceDialog>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            viewableEntities.map(entity => (
              <TableRow key={entity.entity_id}>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <PeopleIcon color="action" />
                    <Typography variant="body2">{getDisplayName(entity)}</Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => removeViewableEntitiesCallback([entity])} disabled={viewOnly}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
