import GroupsIcon from '@mui/icons-material/Groups';
import { Box, ButtonBase, styled, Theme, useMediaQuery, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import formatDateTime from 'components/utils/date_time';
import getWebkitLineClampProperties from 'components/utils/line_clamp';
import React, { useState } from 'react';
import {
  FormErrors,
  PublishStatusProps
} from '../../../../../models/FormProps';
import { getCsrfToken } from '../../../../../utils/csrf';
import { isEmpty } from '../../../../../utils/equality';
import FormCollaboratorIconWithTooltip from './FormCollaboratorIconWithTooltip';
import FormMenu from './FormMenu';
import FormPublishedStatus from './FormPublishedStatus';

export interface FormData {
  formId: string;
  formTitle: string;
  formCreator: string;
  isFormCreator: boolean;
  viewOnly: boolean;
  hasCollaborators: boolean;
  lastModified: string;
  isPublished: boolean;
  updateFormUrl: string;
  editFormUrl: string;
  deleteFormUrl: string;
  duplicateFormUrl: string;
  deleteCollaboratorAccessUrl: string;
  version: number;
}

interface Props {
  formIds: Array<string>;
  formsById: Map<string, FormData>;
  updateForm: (form: FormData) => void;
}

interface FormError {
  formId: string;
  errors?: FormErrors;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold'
  }
}));

const NUM_DESKTOP_TABLE_COLUMNS = 5;
const NUM_MOBILE_TABLE_COLUMNS = 2;

function DesktopTableHeader(): React.ReactElement {
  return (
    <TableRow>
      <StyledTableCell key="isPublished">Publish</StyledTableCell>
      <StyledTableCell key="formTitle">Form Title</StyledTableCell>
      <StyledTableCell key="lastModified">Last Modified</StyledTableCell>
      <StyledTableCell key="formCreator">Created By</StyledTableCell>
      <StyledTableCell key="menu" />
    </TableRow>
  );
}

function MobileTableHeader(): React.ReactElement {
  return (
    <TableRow>
      <StyledTableCell key="formTitle">Form Title</StyledTableCell>
      <StyledTableCell key="menu" />
    </TableRow>
  );
}

interface TableRowProps {
  form: FormData;
  updateIsPublished: (formId: string, isPublished: boolean) => void;
}

function DesktopTableRow({ form, updateIsPublished }: TableRowProps): React.ReactElement {
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell>
        <Switch
          size="small"
          checked={form.isPublished}
          onChange={event => updateIsPublished(event.target.name, event.target.checked)}
          disabled={form.viewOnly}
          color="success"
          name={form.formId}
        />
      </TableCell>
      <TableCell>
        <Link href={form.editFormUrl}>{form.formTitle}</Link>
        {form.hasCollaborators && <FormCollaboratorIconWithTooltip isFormCreator={form.isFormCreator} />}
      </TableCell>
      <TableCell>
        {formatDateTime(form.lastModified)}
      </TableCell>
      <TableCell>
        {form.formCreator}
      </TableCell>
      <TableCell>
        <FormMenu
          deleteFormUrl={form.deleteFormUrl}
          duplicateFormUrl={form.duplicateFormUrl}
          deleteCollaboratorAccessUrl={form.deleteCollaboratorAccessUrl}
          isFormCreator={form.isFormCreator}
        />
      </TableCell>
    </TableRow>
  );
}

function MobileTableRow({ form, updateIsPublished }: TableRowProps): React.ReactElement {
  const theme = useTheme();

  return (
    <TableRow hover sx={{ verticalAlign: 'top' }}>
      <TableCell padding="none">
        <ButtonBase
          onClick={() => { window.location.href = form.editFormUrl; }}
          sx={{
            width: '100%',
            padding: theme.spacing(2),
            justifyContent: 'start',
            textAlign: 'left'
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="body1"
                fontWeight={theme.typography.fontWeightBold}
                marginTop={theme.spacing(1)}
                sx={getWebkitLineClampProperties(2)}
              >
                {form.formTitle}
              </Typography>

              {form.hasCollaborators && <GroupsIcon />}
            </Stack>

            <Box>
              <Typography variant="body2" color="text.secondary">
                {`Created by: ${form.formCreator}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`Last modified: ${formatDateTime(form.lastModified)}`}
              </Typography>
            </Box>

            <FormPublishedStatus isPublished={form.isPublished} />

          </Stack>
        </ButtonBase>
      </TableCell>

      <TableCell sx={{ padding: theme.spacing(0.5) }}>
        <FormMenu
          menuButtonSize="large"
          deleteFormUrl={form.deleteFormUrl}
          duplicateFormUrl={form.duplicateFormUrl}
          deleteCollaboratorAccessUrl={form.deleteCollaboratorAccessUrl}
          isFormCreator={form.isFormCreator}
          publishSettings={{
            toggleIsPublished: () => updateIsPublished(form.formId, !form.isPublished),
            isPublished: form.isPublished,
            isViewOnly: form.viewOnly
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export default function FormsTable(props: Props): React.ReactElement {
  const { formIds, formsById, updateForm } = props;

  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const theme = useTheme();

  const [csrfTokenState, setCsrfTokenState] = useState(getCsrfToken());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [formError, setFormError] = useState<FormError | null>(null);

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{
        overflow: 'auto',
        maxHeight: '100%',
        borderRadius: '4px'
      }}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            {isMobileOrTablet ? <MobileTableHeader /> : <DesktopTableHeader />}
          </TableHead>
          <TableBody>
            {renderTableBody(formIds)}
          </TableBody>
        </Table>
      </TableContainer>

      {formIds.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[8, 15, 25, 50, 100]}
          component="div"
          count={formIds.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          padding="none"
          sx={{
            // We override styles for smaller screens so that the pagination component does not overflow horizontally.
            // The margins for larger screens are exactly the default margins.
            '.MuiTablePagination-input': {
              marginLeft: { xs: 0, lg: theme.spacing(1) },
              marginRight: { xs: theme.spacing(2.5), lg: theme.spacing(4) }
            }
          }}
        />
      )}

      <Snackbar
        open={!!formError}
        onClose={() => setFormError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {renderError()}
      </Snackbar>
    </Paper>
  );

  async function handleUpdateIsPublished(formId: string, isPublished: boolean) {
    const form = formsById.get(formId);

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfTokenState
      },
      body: JSON.stringify({
        form: {
          state: isPublished ? PublishStatusProps.Published : PublishStatusProps.Unpublished,
          lock_version: form.version
        }
      })
    };

    try {
      const response = await fetch(form.updateFormUrl, requestOptions);
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }

      const data = await response.json();
      if (data) {
        setCsrfTokenState(data.csrf_token);
        if (!data.success) {
          setFormError({ formId, errors: data.errors });
          return;
        }

        updateForm({
          ...form,
          isPublished: data.state === PublishStatusProps.Published,
          version: data.version
        });
      }
    } catch (e) {
      setFormError({ formId });
      console.log(e);
    }
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function renderTableBody(formIds: Array<string>) {
    if (formIds.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={isMobileOrTablet ? NUM_MOBILE_TABLE_COLUMNS : NUM_DESKTOP_TABLE_COLUMNS}>
            <Stack alignItems="center" justifyContent="center" paddingY={9}>
              <Typography variant="body1">No forms found.</Typography>
              <Typography variant="body2">Please try another search term.</Typography>
            </Stack>
          </TableCell>
        </TableRow>
      );
    }

    return formIds
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(formId => (
        isMobileOrTablet
          ? <MobileTableRow key={formId} form={formsById.get(formId)} updateIsPublished={handleUpdateIsPublished} />
          : <DesktopTableRow key={formId} form={formsById.get(formId)} updateIsPublished={handleUpdateIsPublished} />
      ));
  }

  function renderError() {
    if (!formError) return;

    const { formTitle } = formsById.get(formError.formId);
    const { version: versionError } = formError.errors;
    const errorMessage = isEmpty(versionError) ? 'An unexpected error occurred.' : versionError;
    return (
      <Alert severity="error">{`Could not update '${formTitle}': ${errorMessage}`}</Alert>
    );
  }
}
