import React, { ChangeEvent } from 'react';
import { AudienceType } from '../../../../../models/FormProps';
import PersonaliseStaffAudience from './PersonaliseStaffAudience';
import PersonaliseStudentAudience from './PersonaliseStudentAudience';
import { TableData } from './AutosaveContainer';

interface Props {
  audienceType: AudienceType;
  viewOnly: boolean;
  tableData: TableData;
  downloadPersonalisedFieldsUrl: string;
  handlePersonalisedFieldsUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: Array<string>;
}

export default function PersonaliseAudience(props: Props) {
  const { audienceType, viewOnly, tableData, downloadPersonalisedFieldsUrl, handlePersonalisedFieldsUpload, errors } = props;

  return audienceType === AudienceType.STAFF
    ? (
      <PersonaliseStaffAudience
        viewOnly={viewOnly}
        tableData={tableData}
        downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
        handlePersonalisedFieldsUpload={handlePersonalisedFieldsUpload}
        errors={errors}
      />
    )
    : (
      <PersonaliseStudentAudience
        viewOnly={viewOnly}
        tableData={tableData}
        downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
        handlePersonalisedFieldsUpload={handlePersonalisedFieldsUpload}
        errors={errors}
      />
    );
}
