import React from 'react';
import Box from '@mui/material/Box';
import { EditableTitle, EditableTitleID } from 'components/pages/sna/form_elements/title/EditableTitle';
import { EditableInstructions, EditableInstructionsID } from 'components/pages/sna/form_elements/instructions/EditableInstructions';
import { RootElementComponent } from 'components/form_elements/RootElement';
import FormProps, { FormErrors } from 'components/models/FormProps';
import AudienceMetadata from 'components/pages/sna/models/AudienceMetadata';

interface Props {
  form: FormProps;
  formErrors: FormErrors;
  selectedElementID: string;
  onSelectElement: (elementID: string) => void;
  viewOnly: boolean;
  onChangeTitle(title: string);
  onChangeInstructions(instructions: string);
  metadata: AudienceMetadata;
}

export default function Form(props: Props) {
  const {
    form,
    formErrors,
    selectedElementID,
    onSelectElement,
    viewOnly,
    onChangeTitle,
    onChangeInstructions,
    metadata,
  } = props;

  return (
    <section className="form">
      <EditableTitle
        title={form.title}
        isSelected={selectedElementID === EditableTitleID}
        onSelectElement={onSelectElement}
        errors={formErrors.title}
        onChangeTitle={onChangeTitle}
        viewOnly={viewOnly}
      />
      <EditableInstructions
        instructions={form.instructions}
        isSelected={selectedElementID === EditableInstructionsID}
        onSelectElement={onSelectElement}
        errors={formErrors.instructions}
        onChangeInstructions={onChangeInstructions}
        viewOnly={viewOnly}
        metadataHeaders={metadata.headers}
      />

      <Box className="form-body">
        <RootElementComponent element={form.body} />
      </Box>
    </section>
  );
}
