import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { isEmpty } from '../../../../../../utils/equality';
import NoRosterContent from '../../NoRosterContent';
import StudentAudienceSelector from './selectors/StudentAudienceSelector';
import SelectedStudentAudience from './selected_students/SelectedStudentAudience';
import {
  AudienceType,
  Clazz,
  isEntityEmpty,
  Level,
  newStudentAudienceProps,
  School,
  Student,
  StudentAudienceProps,
  TeachingGroup
} from '../../../../../../models/FormProps';
import { AudienceStage } from '../../Content';

interface Props {
  school: School;
  viewableAudience: StudentAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps) => void;
  setMaxStepCallback: (stage: AudienceStage) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  enableTeachingGroups: boolean;
}

export default function StudentSelectorContent(props: Props): React.ReactElement {
  const { viewableAudience, school, isHQUser, viewOnly, getSchoolsUrl, getStudentRosterUrl, updateViewableAudienceCallback, setMaxStepCallback, enableTeachingGroups } = props;
  const [schools, setSchools] = useState<Array<School>>([]);
  const scrollContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isHQUser) getSchools();
  }, []);

  return (isEntityEmpty(school) && !isHQUser) ? <NoRosterContent /> : renderContent();

  function renderContent() {
    if (isEntityEmpty(school) && isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <Grid container spacing={3}>
        <Grid ref={scrollContainerRef} item xs={5} className="audience-editor-audience-selector-container">
          <StudentAudienceSelector
            scrollContainerRef={scrollContainerRef}
            school={school}
            schools={schools}
            viewableAudience={viewableAudience}
            addViewableEntitiesCallback={addViewableEntitiesCallback}
            changeAcademicYearCallback={changeAcademicYearCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
            getStudentRosterUrl={getStudentRosterUrl}
            enableTeachingGroups={enableTeachingGroups}
          />
        </Grid>
        <Grid item xs={7} className="audience-editor-audience-selector-container">
          <SelectedStudentAudience
            schools={schools}
            viewableAudience={viewableAudience}
            removeViewableEntitiesCallback={removeViewableEntitiesCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
            enableTeachingGroups={enableTeachingGroups}
          />
        </Grid>
      </Grid>
    );
  }

  function addViewableEntitiesCallback(viewableEntities: Array<School | Level | Clazz | TeachingGroup | Student>) {
    const entitiesToAdd = viewableEntities
      .filter(entityToAdd => !viewableAudience.entities.some(entity => entity.entity_id === entityToAdd.entity_id && entity.entity_type === entityToAdd.entity_type));

    const entities = [...viewableAudience.entities, ...entitiesToAdd];
    updateViewableAudienceCallback(newStudentAudienceProps({ entities, year: viewableAudience.year }));
    setMaxStepCallback(AudienceStage.PersonaliseFields);
  }

  function changeAcademicYearCallback(year: number) {
    updateViewableAudienceCallback(newStudentAudienceProps({ year }));
  }

  function removeViewableEntitiesCallback(viewableEntitiesToRemove: Array<School | Level | Clazz| TeachingGroup | Student>) {
    const entities = viewableAudience.entities
      .filter(entity => !viewableEntitiesToRemove
        .some(entityToRemove => entity.entity_id === entityToRemove.entity_id && entity.entity_type === entityToRemove.entity_type));

    if (isEmpty(entities)) {
      setMaxStepCallback(AudienceStage.SelectIndividualsOrGroups);
    }

    updateViewableAudienceCallback(newStudentAudienceProps({ entities, year: viewableAudience.year }));
  }

  function getSchools() {
    const url = new URL(getSchoolsUrl);
    url.search = new URLSearchParams({ type: AudienceType.STUDENT }).toString();

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setSchools(data.schools);
        }
      });
  }
}
