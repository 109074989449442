/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react';
import Button from '@mui/material/Button';

import { AudienceType, newUntargetedStudentAudienceProps, newStudentAudienceProps, UntargetedStudentAudienceProps, StudentAudienceProps } from '../../../../../models/FormProps';
import { AudienceStage } from '../Content';
import { isNotEmpty } from '../../../../../utils/equality';
import TargetedStudentImage from '../../../../../../../assets/images/targeted_untargeted_selector/targeted-student.svg';
import UntargetedStudentImage from '../../../../../../../assets/images/targeted_untargeted_selector/untargeted-student.svg';

interface Props {
  viewableAudience: StudentAudienceProps | UntargetedStudentAudienceProps;
  viewOnly: boolean;
  setActiveStepCallback: (stage: AudienceStage) => void;
  setMaxStepCallback: (stage: AudienceStage) => void;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps | UntargetedStudentAudienceProps) => void;
}

export default function TargetedUntargetedStudentSelector(props: Props): React.ReactElement {
  const { viewableAudience, viewOnly, updateViewableAudienceCallback, setActiveStepCallback, setMaxStepCallback } = props;

  return (
    <>
      <Button
        className={viewableAudience.type === AudienceType.STUDENT && isNotEmpty(viewableAudience.entities)
          ? 'audience-editor-button-selected' : 'audience-editor-button'}
        variant="outlined"
        onClick={() => selectTargetedAudienceCallback()}
        disabled={viewOnly}
      >
        <img alt="Specific students only" src={TargetedStudentImage} className="audience-editor-button-image" />
      </Button>
      <Button
        className={viewableAudience.type === AudienceType.UNTARGETED_STUDENT ? 'audience-editor-button-selected' : 'audience-editor-button'}
        variant="outlined"
        onClick={() => selectUntargetedAudienceCallback()}
        disabled={viewOnly}
      >
        <img alt="Any students" src={UntargetedStudentImage} className="audience-editor-button-image" />
      </Button>
    </>
  );

  function selectTargetedAudienceCallback() {
    if (viewableAudience.type === AudienceType.UNTARGETED_STUDENT) {
      if (!confirm('This will restrict the form audience to selected students. If you have already collected responses, make sure you select those students in the next page to continue viewing their responses. Click to proceed.')) return;
      updateViewableAudienceCallback(newStudentAudienceProps({}));
    }

    setMaxStepCallback(AudienceStage.SelectIndividualsOrGroups);
    setActiveStepCallback(AudienceStage.SelectIndividualsOrGroups);
  }

  function selectUntargetedAudienceCallback() {
    if (viewableAudience.type === AudienceType.STUDENT) {
      if (isNotEmpty(viewableAudience.entities) && !confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;
      setMaxStepCallback(AudienceStage.SelectedTargetedOrUntargetedAudience);
      updateViewableAudienceCallback(newUntargetedStudentAudienceProps());
    }
  }
}
