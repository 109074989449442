import React from 'react';
import classNames from 'classnames';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { isNotEmpty } from 'components/utils/equality';

export const EditableTitleID = 'EditableTitle';

interface EditableTitleProps {
  title: string;
  errors?: Array<string>;
  isSelected: boolean;
  onSelectElement: (elementID: string) => void;
  onChangeTitle: (title: string) => void;
  viewOnly: boolean;
}

const defaultProps = {
  errors: []
};

export function EditableTitle(props: EditableTitleProps): React.ReactElement {
  const {
    title,
    isSelected,
    onSelectElement,
    onChangeTitle,
    viewOnly,
    errors = defaultProps.errors
  } = props;

  const className = classNames('form-element form-title-element selectable', { selected: isSelected });
  const hasErrors = isNotEmpty(errors);

  return (
    <article className={className} onClick={() => onSelectElement(EditableTitleID)}>
      <header className="element-header">
        <Typography>FORM TITLE</Typography>
        {hasErrors && <Alert className="errors" severity="error">{errors.length}</Alert>}
      </header>

      <TextField
        className="element-content"
        autoFocus
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        size="small"
        value={title}
        onChange={event => onChangeTitle(event.target.value)}
        error={hasErrors}
        helperText={hasErrors && errors[0]}
        disabled={viewOnly}
      />
    </article>
  );
}
