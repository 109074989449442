import React from 'react';

import { AudienceType, School, StaffAudienceProps, StudentAudienceProps } from '../../../../../models/FormProps';
import { AudienceStage } from '../Content';
import StaffSelectorContent from './staff_selector/StaffSelectorContent';
import StudentSelectorContent from './student_selector/StudentSelectorContent';

interface Props {
  school: School;
  viewableAudience: StaffAudienceProps | StudentAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StaffAudienceProps | StudentAudienceProps) => void;
  setMaxStepCallback: (stage: AudienceStage) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  getStaffRosterUrl: string;
  enableTeachingGroups: boolean;
}

export default function AudienceSelector(props: Props): React.ReactElement {
  const {
    school,
    viewableAudience,
    updateViewableAudienceCallback,
    setMaxStepCallback,
    viewOnly,
    isHQUser,
    getSchoolsUrl,
    getStudentRosterUrl,
    getStaffRosterUrl,
    enableTeachingGroups
  } = props;

  return (
    viewableAudience.type === AudienceType.STAFF
      ? (
        <StaffSelectorContent
          viewableAudience={viewableAudience as StaffAudienceProps}
          school={school}
          updateViewableAudienceCallback={updateViewableAudienceCallback}
          setMaxStepCallback={setMaxStepCallback}
          viewOnly={viewOnly}
          isHQUser={isHQUser}
          getSchoolsUrl={getSchoolsUrl}
          getStaffRosterUrl={getStaffRosterUrl}
        />
      )
      : (
        <StudentSelectorContent
          viewableAudience={viewableAudience as StudentAudienceProps}
          school={school}
          updateViewableAudienceCallback={updateViewableAudienceCallback}
          setMaxStepCallback={setMaxStepCallback}
          viewOnly={viewOnly}
          isHQUser={isHQUser}
          getSchoolsUrl={getSchoolsUrl}
          getStudentRosterUrl={getStudentRosterUrl}
          enableTeachingGroups={enableTeachingGroups}
        />
      )
  );
}
