import React from 'react';
import Box from '@mui/system/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import { isEmpty, isNotEmpty, isNonEmptyObject } from '../../../utils/equality';
import { ElementComponentProps, isElementComponentPropsEqual, newElementID, renderHeader } from '../../BaseElement';
import { BaseQuestion, newBaseQuestion, QuestionTypePrefix, renderQuestionText, extractErrorsIfPresent } from '../BaseQuestion';
import { LimitedResponsesErrorsByQnID } from '../../../models/FormProps';

export const MultipleChoiceQuestionType = `${QuestionTypePrefix}::Mcq`;
const MultipleChoiceQuestionOptionType = `${MultipleChoiceQuestionType}::Option`;

export interface MultipleChoiceQuestion extends BaseQuestion {
  options: Array<MultipleChoiceQuestionOption>;
  is_dropdown: boolean;
  view_remaining_options: boolean;
}

export interface MultipleChoiceQuestionOption {
  id: string;
  type: string;
  value: string;
  limited_responses?: number;
}

export function newMultipleChoiceQuestion(element?: MultipleChoiceQuestion) {
  const { options, view_remaining_options } = element || {};
  const newOptions = isEmpty(options)
    ? Array(3).fill(null).map((_, i) => newMultipleChoiceQuestionOption({ id: null, type: null, value: `Option ${i + 1}`, limited_responses: null }))
    : options.map(option => newMultipleChoiceQuestionOption({ id: null, type: option.type, value: option.value, limited_responses: option.limited_responses }));

  const props = {
    ...newBaseQuestion(element),
    type: MultipleChoiceQuestionType,
    view_remaining_options,
    options: newOptions
  };

  return props;
}

export function newMultipleChoiceQuestionOption(props?: MultipleChoiceQuestionOption) {
  const { id, value, limited_responses } = props || {};

  return {
    id: id || newElementID(),
    type: MultipleChoiceQuestionOptionType,
    value: value || 'Option',
    limited_responses
  };
}

export const MultipleChoiceQuestionComponent = React.memo(multipleChoiceQuestionComponent, isElementComponentPropsEqual);

export function multipleChoiceQuestionComponent(props: ElementComponentProps<MultipleChoiceQuestion>) {
  const { element, numErrors, answerMode, qnNum } = props;

  const limitedResponseError = extractLimitedResponseErrorIfPresent(answerMode?.limitedResponsesErrors);
  const hasAnswerErrors = limitedResponseError !== '' || isNonEmptyObject(answerMode?.answer?.errors);

  return (
    <Box className={classNames('form-element', 'form-multiple-choice-question', { 'form-element-error': hasAnswerErrors })}>
      {renderHeader(`QUESTION ${qnNum} - MULTIPLE CHOICE`, element.required, numErrors, isNotEmpty(element.display_conditions_container.display_conditions), isEmpty(answerMode))}
      {renderQuestionText(element.text)}

      {element.is_dropdown ? renderDropdown() : renderOptions()}
    </Box>
  );

  function renderOptions() {
    return (
      <FormControl error={hasAnswerErrors}>
        <RadioGroup className="answer" value={answerMode?.answer?.values[0] || ''} onChange={onChangeRadioOption}>
          {element.options.map(option => {
            const remainingSlots = getRemainingSlots(option, answerMode);

            return (
              <Stack direction="row" key={option.id}>
                <FormControlLabel
                  className="option-container"
                  control={(
                    <Radio
                      className="option"
                      value={option.id}
                      disabled={remainingSlots === 0}
                    />
                  )}
                  label={option.value}
                />
                {(remainingSlots !== null && element.view_remaining_options) &&
                  <Typography className="remaining-slots" variant="caption">{`(Remaining: ${remainingSlots})`}</Typography>}
              </Stack>
            );
          })}
        </RadioGroup>
        <FormHelperText className="form-helper-text">
          {limitedResponseError || extractErrorsIfPresent(answerMode?.answer?.errors)}
        </FormHelperText>
      </FormControl>
    );
  }

  function renderDropdown() {
    const answer = isEmpty(answerMode) ? '' : answerMode.answer.values[0];

    return (
      <FormControl size="small" className="answer dropdown">
        <Select onChange={onChangeDropdownOption} value={answer} defaultValue="[Select an option]" displayEmpty>
          <MenuItem key="empty-option" value="">[Select an option]</MenuItem>
          {
            element.options.map(option => {
              const remainingSlots = getRemainingSlots(option, answerMode);

              return (
                <MenuItem disabled={remainingSlots === 0} key={option.id} value={option.id}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>{option.value}</Typography>
                    {(remainingSlots !== null && element.view_remaining_options) &&
                      <Typography className="form-dropdown-remaining-slots" variant="caption">{`(Remaining: ${remainingSlots})`}</Typography>}
                  </Stack>
                </MenuItem>
              );
            })
          }
        </Select>
        <FormHelperText className="form-helper-text" error>
          {limitedResponseError || extractErrorsIfPresent(answerMode?.answer?.errors)}
        </FormHelperText>
      </FormControl>
    );
  }

  function onChangeDropdownOption(event: SelectChangeEvent) {
    if (isEmpty(answerMode)) return;

    answerMode.onChangeAnswer({ ...answerMode.answer, values: [event.target.value as string] });
  }

  function extractLimitedResponseErrorIfPresent(errors?: LimitedResponsesErrorsByQnID): string {
    if (isEmpty(errors)) return '';

    return errors[element.id] ?? '';
  }

  function onChangeRadioOption(event: React.ChangeEvent<HTMLInputElement>, value: string) {
    if (isEmpty(answerMode)) return;

    answerMode.onChangeAnswer({ ...answerMode.answer, values: [value] });
  }

  function getRemainingSlots(option, answerMode) {
    let remainingSlots = null;

    if (isEmpty(option.limited_responses)) {
      return remainingSlots;
    }

    remainingSlots = isEmpty(answerMode) || isEmpty(answerMode?.questionOptionsResponsesByQnID[element.id])
      ? option.limited_responses
      : option.limited_responses - Object.keys(answerMode?.questionOptionsResponsesByQnID[element.id][option.id]).length;
    return Math.max(remainingSlots, 0);
  }
}
