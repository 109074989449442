import { Link, List, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HTMLForm from 'components/pages/common/HTMLForm';
import { isEmpty } from 'components/utils/equality';
import React from 'react';
import SnaAppProvider from 'components/pages/sna/SnaAppProvider';
import SnaWeb from '../../../../../../../assets/images/sna/sna-web.svg';
import HuedSunflower from '../../../../../../../assets/images/sna/sunflowers/hued-sunflower.svg';
import FormsTable from './forms_table/FormsTable';
import SideBar, { SideBarProps } from './SideBar';
import SnaFeaturesDialog from './SnaFeaturesDialog';

export interface FormData {
  formID: string;
  formTitle: string;
  formCreator: string;
  isFormCreator: boolean;
  viewOnly: boolean;
  hasCollaborators: boolean;
  lastModified: Date;
  isPublished: boolean;
  updateFormUrl: string;
  editFormUrl: string;
  deleteFormUrl: string;
  duplicateFormUrl: string;
  deleteCollaboratorAccessUrl: string;
  version: number;
}

interface Props {
  createNewFormUrl: string;
  formsData: Array<FormData>;
  snaContactEmail: string;
  sideBarProps: SideBarProps;
}

export default function Page(props: Props): React.ReactElement {
  const { createNewFormUrl, formsData, snaContactEmail, sideBarProps } = props;

  return (
    <SnaAppProvider>
      <div className="container">
        <SideBar {...sideBarProps} />
        <Stack spacing={3} className="content" justifyContent="flex-start">
          {renderDescriptionContent()}
          {renderFormsTableContent()}
        </Stack>
      </div>
    </SnaAppProvider>
  );

  function renderDescriptionContent() {
    return (
      <Box className="description" height="min-content">
        <Stack className="header" direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h5">
            Easily picture your students&apos; peer relations with a sociogram
          </Typography>
          <Stack direction="row" justifyContent="flex-end" minWidth="max-content" spacing={2}>
            <SnaFeaturesDialog createNewFormUrl={createNewFormUrl} />
            <HTMLForm action={createNewFormUrl} method="POST">
              {({ onSubmit }) => (
                <Button variant="contained" onClick={onSubmit}>
                  <Typography variant="landingButton">
                    NEW SOCIOGRAM
                  </Typography>
                </Button>
              )}
            </HTMLForm>
          </Stack>
        </Stack>

        <Grid item container spacing={1}>
          <Grid item xs={3} display="flex" justifyContent="center">
            <img width="90%" alt="" src={SnaWeb} />
          </Grid>
          <Grid item xs={9}>
            <List>
              <ListItem>
                <Box>
                  <Typography fontWeight="bold">
                    What is a sociogram?<br />
                  </Typography>
                  <Typography>
                    A sociogram is a visual representation of the social links between students.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box>
                  <Typography fontWeight="bold">
                    Why create a sociogram?<br />
                  </Typography>
                  <Typography>
                    With a sociogram, you get valuable data on how your students are mixing, allowing you to quickly pinpoint students who need more support.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box>
                  <Typography fontWeight="bold">
                    How do I interpret the sociogram?<br />
                  </Typography>
                  <Typography>
                    You will receive a summary report that helps you unpack the findings from the sociogram.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Typography color="#33658a" variant="body2">
                  Create a sociogram today by surveying your students.<br />
                  Questions? Email us at <Link color="inherit" href={`mailto:${snaContactEmail}`}>{snaContactEmail}</Link>
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFormsTableContent() {
    return (
      <Box paddingLeft="24px">
        <Typography variant="h5" color="#282E45">
          Your sociograms
        </Typography>
        <Box display="flex" justifyContent="center" paddingRight="42px" paddingBottom="12px" paddingTop="8px">
          {renderFormsTable()}
        </Box>
      </Box>
    );
  }

  function renderFormsTable() {
    if (isEmpty(formsData)) {
      return (
        <Stack spacing={2} direction="column" className="no-forms-created">
          <img alt="" src={HuedSunflower} />
          <Typography variant="body1">
            No sociograms created yet
          </Typography>
        </Stack>
      );
    } else {
      return <FormsTable data={formsData} />;
    }
  }
}
