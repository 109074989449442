import React, { useRef, useState } from 'react';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import FormProps, { FormErrors } from '../../../../models/FormProps';
import { addTask } from '../../../../utils/add_task';
import { getCsrfToken } from '../../../../utils/csrf';
import usePromptWindowUnload, { removePromptWindowUnload } from '../../../../utils/prompt_window_unload';
import { useDidUpdateEffect } from '../CustomHooks';
import Content from './Content';
import ExceedQuestionsLimitSnackbar from './ExceedQuestionsLimitSnackbar';

interface Props {
  form: FormProps;
  template: TemplateUsageInstructions;
  version: number;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
  updateUrl: string;
  updateFormImageUrl: string;
  previewUrl: string;
  formUrl: string;
  formsUrl: string;
  createAnnouncementOnPgUrl: string;
  viewOnly: boolean;
  isShareableToPG: boolean;
}

export enum SaveStatus {
  Saved = 0,
  Saving,
  Error
}

export default function ContentContainer(props: Props): React.ReactElement {
  const { form, updateUrl, viewOnly, version } = props;

  const saveTimerRef = useRef<number>();

  const [formState, setFormState] = useState(form);
  const formVersion = useRef<number>(version);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [csrfTokenState, setCsrfTokenState] = useState(getCsrfToken());
  const [saveStatus, setSaveStatus] = useState(SaveStatus.Saved);
  const [isExceedQuestionsLimitSnackbarOpen, setIsExceedQuestionsLimitSnackbarOpen] = useState(false);

  if (!viewOnly) useDidUpdateEffect(saveWithDelay, [formState]);
  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  return (
    <>
      <Content
        {...props}
        formErrors={formErrors}
        saveStatus={saveStatus}
        onChangeForm={onChangeForm}
        setIsExceedQuestionsLimitSnackbarOpenCallback={() => setIsExceedQuestionsLimitSnackbarOpen(true)}
      />
      <ExceedQuestionsLimitSnackbar
        isSnackbarOpen={isExceedQuestionsLimitSnackbarOpen}
        setIsSnackbarOpenCallback={setIsExceedQuestionsLimitSnackbarOpen}
      />
    </>
  );

  function onChangeForm(form: FormProps) {
    setFormState(form);
  }

  function saveWithDelay() {
    setSaveStatus(SaveStatus.Saving);
    clearTimeout(saveTimerRef.current);
    saveTimerRef.current = window.setTimeout(save, 1000);

    return function cleanup() {
      clearTimeout(saveTimerRef.current);
    };
  }

  function save() {
    return addTask(updateFormTask);
  }

  function updateFormTask() {
    setSaveStatus(SaveStatus.Saving);
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfTokenState
      },
      body: JSON.stringify({
        form: {
          ...formState,
          lock_version: formVersion.current
        }
      })
    };

    return fetch(updateUrl, options)
      .then(response => {
        if (response.redirected) {
          removePromptWindowUnload();
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setCsrfTokenState(data.csrf_token);
          setFormErrors(processErrors(data.errors));
          setSaveStatus(data.success ? SaveStatus.Saved : SaveStatus.Error);
          if (data.success) formVersion.current = data.version;
        }
      })
      .catch(e => {
        console.log(e);
        setSaveStatus(SaveStatus.Error);
      });
  }

  function processErrors(errors: FormErrors): FormErrors {
    return {
      ...errors,
      bodyByElementID: errors.body?.reduce((hash, element) => { hash[element.element_id] = element.errors; return hash; }, {}) // group body errors by element id
    };
  }
}
