import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { List, WindowScroller, AutoSizer, ListRowRenderer } from 'react-virtualized';
import {
  Clazz,
  Level,
  School,
  Student,
  StudentAudienceProps,
  StudentEntityType,
  Subject,
  TeachingGroup
} from '../../../../../../../models/FormProps';
import AudienceMenu from '../../common/AudienceMenu';
import SearchResultsButton from '../../../../common/SearchResultsButton';
import StudentInfoButton from './StudentInfoButton';
import { AudienceFilters } from './StudentAudienceGroupSelectorContainer';
import SubjectMenu from './SubjectMenu';

interface Props {
  viewableAudience: StudentAudienceProps;
  students: Array<Student>;
  clazzes: Array<Clazz>;
  teachingGroups: Array<TeachingGroup>;
  subjects: Array<Subject>;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Level | Clazz | TeachingGroup>) => void;
  setAudienceFiltersCallback: (audienceFilters: AudienceFilters) => void;
  viewOnly: boolean;
  enableTeachingGroups: boolean;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>
}

interface AudienceEntityAndAnchorEl {
  anchorElement: HTMLElement;
  entity: Clazz | TeachingGroup;
}

interface SubjectAndAnchorEl {
  anchorElement: HTMLElement;
  subject: Subject;
}

interface RenderVirtualisedResultsParams {
  searchTitle: string;
  numRows: number;
  rowRenderer: ListRowRenderer;
  renderMenu?: () => JSX.Element;
}

const ROW_HEIGHT = 43;

export default function StudentAudienceSearchResults(props: Props): React.ReactElement {
  const {
    viewableAudience,
    students,
    clazzes,
    teachingGroups,
    subjects,
    addViewableEntitiesCallback,
    setAudienceFiltersCallback,
    viewOnly,
    enableTeachingGroups,
    scrollContainerRef
  } = props;
  const selectedStudentsIdsSet = getCurrentSelectedStudentsIdsSet(viewableAudience);
  const selectedClazzesIdsSet = getCurrentSelectedClassesIdsSet(viewableAudience);
  const selectedTeachingGroupsIdsSet = getCurrentSelectedTeachingGroupsIdsSet(viewableAudience);
  const [audienceEntityAndAnchorEl, setAudienceEntityAndAnchorEl] = useState<AudienceEntityAndAnchorEl>(null);
  const [subjectAndAnchorEl, setSubjectAndAnchorEl] = useState<SubjectAndAnchorEl>(null);

  return (
    <Stack spacing={2}>
      <Typography variant="caption">SEARCH RESULTS ({students.length + clazzes.length + teachingGroups.length + subjects.length})</Typography>
      <Stack className="results" spacing={2}>
        {students.length !== 0 && renderVirtualisedResults({ searchTitle: 'Students', numRows: students.length, rowRenderer: renderStudentRow })}
        {
          clazzes.length !== 0 &&
            renderVirtualisedResults({
              searchTitle: 'Form classes',
              numRows: clazzes.length,
              rowRenderer: renderClazzRow,
              renderMenu: () => renderAudienceMenu('class', StudentEntityType.CLAZZ)
            })
        }
        {
          enableTeachingGroups && teachingGroups.length !== 0 &&
            renderVirtualisedResults({
              searchTitle: 'Teaching groups',
              numRows: teachingGroups.length,
              rowRenderer: renderTeachingGroupRow,
              renderMenu: () => renderAudienceMenu('teaching group', StudentEntityType.TEACHING_GROUP)
            })
        }
        {
          enableTeachingGroups && subjects.length !== 0 &&
            renderVirtualisedResults({
              searchTitle: 'Subjects',
              numRows: subjects.length,
              rowRenderer: renderSubjectRow,
              renderMenu: renderSubjectMenu
            })
        }
      </Stack>
    </Stack>
  );

  function renderVirtualisedResults(params: RenderVirtualisedResultsParams) {
    const { searchTitle, numRows, rowRenderer, renderMenu } = params;

    return (
      <Stack spacing={1}>
        <Typography variant="body2" fontWeight="bold">{searchTitle}</Typography>

        <WindowScroller scrollElement={scrollContainerRef.current}>
          {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => {
            /**
             * This fix is needed due to an undefined height during the first render of WindowScroller, resulting in a console error
             * Adapted from: https://github.com/bvaughn/react-virtualized/pull/1349#issuecomment-884380070
             * The fix invokes the getBoundingClientRect() API directly instead calling getDimensions()
             *
             * More context regarding the errors can be found below:
             * https://github.com/bvaughn/react-virtualized/issues/1158
             * https://github.com/bvaughn/react-virtualized/issues/1090
             */
            let containerHeight = height;
            if (height === undefined) {
              containerHeight = scrollContainerRef.current.getBoundingClientRect().height;
            }

            return (
              <AutoSizer disableHeight>
                {({ width }) => (
                /**
                 * This line is needed otherwise a rendering bug will occur.
                 * https://github.com/bvaughn/react-virtualized/issues/1324#issuecomment-477989552
                 */
                  <div ref={el => registerChild(el)}>
                    <List
                      autoHeight
                      height={containerHeight}
                      width={width}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={rowRenderer}
                      rowCount={numRows}
                    />
                  </div>
                )}
              </AutoSizer>
            );
          }}
        </WindowScroller>
        {renderMenu !== undefined && renderMenu()}
      </Stack>
    );
  }

  function renderStudentRow({ index, key, style }) {
    const value = students[index];

    return (
      <Box key={key} style={style}>
        <StudentInfoButton
          student={value}
          onClickCallback={() => addViewableEntitiesCallback([value])}
          disabled={viewOnly || selectedStudentsIdsSet.has(value.entity_id)}
        />
      </Box>
    );
  }

  function renderClazzRow({ index, key, style }) {
    const value = clazzes[index];

    return (
      <Box key={key} style={style}>
        <SearchResultsButton
          onClickCallback={e => handleClickOnAudienceButton(e, value)}
          disabled={viewOnly || selectedClazzesIdsSet.has(value.entity_id)}
        >
          {value.name}
        </SearchResultsButton>
      </Box>
    );
  }

  function renderTeachingGroupRow({ index, key, style }) {
    const value = teachingGroups[index];

    return (
      <Box key={key} style={style}>
        <SearchResultsButton
          onClickCallback={e => handleClickOnAudienceButton(e, value)}
          disabled={viewOnly || selectedTeachingGroupsIdsSet.has(value.entity_id)}
        >
          {value.name}
        </SearchResultsButton>
      </Box>
    );
  }

  function renderAudienceMenu(groupName: string, entityType: StudentEntityType) {
    return (
      audienceEntityAndAnchorEl !== null && audienceEntityAndAnchorEl.entity.entity_type === entityType && (
        <AudienceMenu
          groupName={groupName}
          targetAudience="students"
          audienceButtonAnchorEl={audienceEntityAndAnchorEl.anchorElement}
          onCloseCallback={() => setAudienceEntityAndAnchorEl(null)}
          onAddGroupCallback={handleAddAudienceEntity}
          onSelectFromGroupCallback={handleSelectAudienceGroup}
        />
      )
    );
  }

  function renderSubjectRow({ index, key, style }) {
    const subject = subjects[index];

    return (
      <Box key={key} style={style}>
        <SearchResultsButton
          onClickCallback={e => handleClickOnSubjectButton(e, subject)}
          disabled={viewOnly}
        >
          {subject}
        </SearchResultsButton>
      </Box>
    );
  }

  function renderSubjectMenu() {
    return (
      subjectAndAnchorEl !== null && (
        <SubjectMenu
          subjectAnchorEl={subjectAndAnchorEl.anchorElement}
          onCloseCallback={() => setSubjectAndAnchorEl(null)}
          onSelectTeachingGroupsFromSubjectCallback={handleSelectTeachingGroupsFromSubject}
          onSelectStudentsFromSubjectCallback={handleSelectStudentsFromSubject}
        />
      )
    );
  }

  function getCurrentSelectedStudentsIdsSet(audience: StudentAudienceProps) {
    const studentEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.STUDENT);
    return new Set(studentEntities.map(entity => entity.entity_id));
  }

  function getCurrentSelectedClassesIdsSet(audience: StudentAudienceProps) {
    const clazzEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.CLAZZ);
    return new Set(clazzEntities.map(entity => entity.entity_id));
  }

  function getCurrentSelectedTeachingGroupsIdsSet(audience: StudentAudienceProps) {
    const teachingGroupEntities = audience.entities.filter(entity => entity.entity_type === StudentEntityType.TEACHING_GROUP);
    return new Set(teachingGroupEntities.map(entity => entity.entity_id));
  }

  function handleClickOnAudienceButton(event: React.MouseEvent<HTMLButtonElement>, viewableEntity: Clazz | TeachingGroup) {
    setAudienceEntityAndAnchorEl({ entity: viewableEntity, anchorElement: event.currentTarget });
  }

  function handleAddAudienceEntity() {
    addViewableEntitiesCallback([audienceEntityAndAnchorEl.entity]);
    setAudienceEntityAndAnchorEl(null);
  }

  function handleSelectAudienceGroup() {
    setAudienceFiltersCallback({ studentsFilter: audienceEntityAndAnchorEl.entity });
    setAudienceEntityAndAnchorEl(null);
  }

  function handleClickOnSubjectButton(event: React.MouseEvent<HTMLButtonElement>, subject: string) {
    setSubjectAndAnchorEl({ subject, anchorElement: event.currentTarget });
  }

  function handleSelectTeachingGroupsFromSubject() {
    setAudienceFiltersCallback({ teachingGroupsFilter: subjectAndAnchorEl.subject });
  }

  function handleSelectStudentsFromSubject() {
    setAudienceFiltersCallback({ studentsFilter: subjectAndAnchorEl.subject });
  }
}
