import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import { DEFAULT_QUESTION_TEXT } from 'components/utils/default_text';
import { FormAnswerErrorsByAttribute } from '../../models/FormProps';
import { isEmpty } from '../../utils/equality';
import { modifyAnchorTagBehaviour, sanitizeHtmlInString } from '../../utils/html';
import { BaseElement, newBaseElement } from '../BaseElement';
import { defaultDisplayConditionsContainer, DisplayConditionsContainer } from '../display_condition/DisplayConditionsContainer';
import { LongTextQuestion } from './long_text/LongTextQuestion';
import { MultipleChoiceQuestion } from './multiple_choice/MultipleChoiceQuestion';
import { MultipleResponseQuestion } from './multiple_response/MultipleResponseQuestion';
import { RankingQuestion } from './ranking/RankingQuestion';

export const QuestionTypePrefix = 'FormElement::Question';

export interface BaseQuestion extends BaseElement {
  text: string;
  required: boolean;
  display_conditions_container: DisplayConditionsContainer;
}

export function newBaseQuestion(element?: BaseQuestion) {
  const { text, required, display_conditions_container } = element || {};

  return {
    ...newBaseElement(),
    text: (text != null) ? text : DEFAULT_QUESTION_TEXT,
    required: (required != null) ? required : true,
    display_conditions_container: display_conditions_container || defaultDisplayConditionsContainer()
  };
}

export type UnionQuestion =
  LongTextQuestion |
  RankingQuestion |
  MultipleChoiceQuestion |
  MultipleResponseQuestion;

export function renderQuestionText(text: string) {
  const sanitizedText = sanitizeHtmlInString(text);
  const modifiedText = modifyAnchorTagBehaviour(sanitizedText);

  // eslint-disable-next-line react/no-danger -- HTML string is sanitised.
  return <p dangerouslySetInnerHTML={{ __html: modifiedText }} />;
}

export function extractErrorsIfPresent(errors?: FormAnswerErrorsByAttribute) {
  if (isEmpty(errors)) return null;

  return Object.values(errors).join('. ');
}

export function isQuestionType(type: string) {
  return type.startsWith(QuestionTypePrefix);
}
