import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import AllEarsImage from '../../assets/images/full-logo.svg';
import HTMLForm from './pages/common/HTMLForm';

interface Props {
  username?: string;
  logOutUrl?: string;
  homeUrl: string;
  children?: JSX.Element | Array<JSX.Element>;
}

const UserCard = (props: { username: string, logOutUrl: string}) => {
  const { username, logOutUrl } = props;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  return (
    <>
      <ButtonBase className="user" onClick={handleUserCardClick}>
        <div>
          <Typography variant="caption">WELCOME</Typography>
          <Typography variant="body1" className="name">{username}</Typography>
        </div>
      </ButtonBase>

      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableScrollLock
      >
        <HTMLForm action={logOutUrl} method="POST">
          {({ onSubmit }) => (
            <MenuItem onClick={onSubmit}>Log out</MenuItem>)}
        </HTMLForm>
      </Menu>
    </>
  );

  function handleUserCardClick(event: React.MouseEvent<HTMLElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchorEl(null);
  }
};

export default function TopBar(props: Props) {
  const { username, logOutUrl, homeUrl, children } = props;

  return (
    <AppBar className="top-bar" color="inherit" elevation={0}>
      <Toolbar className="content">
        <a href={homeUrl}>
          <Avatar src={AllEarsImage} variant="rounded" className="logo" alt="All Ears Form Builder" />
        </a>

        {children}

        {username && <UserCard username={username} logOutUrl={logOutUrl} />}
      </Toolbar>
    </AppBar>
  );
}
