import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { groupBy } from 'components/utils/group_by';
import {
  TeachingGroup
} from '../../../../../../../models/FormProps';
import DeleteAudienceDialog from '../../common/DeleteAudienceDialog';

interface Props {
  removeViewableEntitiesCallback: (viewableEntities: Array<TeachingGroup>) => void;
  viewOnly: boolean;
  viewableEntities: Array<TeachingGroup>;
}

export default function TeachingGroupTable(props: Props): React.ReactElement {
  const { viewableEntities, removeViewableEntitiesCallback, viewOnly } = props;
  const teachingGroupsBySubject: { [key: string]: Array<TeachingGroup> } = getTeachingGroupsbySubjects(viewableEntities);

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="Selected teaching groups">
        <TableHead className="table-header">
          <TableRow>
            <TableCell>{`Teaching Group (${viewableEntities.length})`}</TableCell>
            <TableCell align="right">
              <DeleteAudienceDialog
                icon={<DeleteIcon fontSize="small" htmlColor="white" />}
                viewOnly={viewOnly}
                removeAudienceCallback={() => removeViewableEntitiesCallback(viewableEntities)}
              >
                <DialogTitle>Remove all selected teaching groups?</DialogTitle>
                <DialogContent>
                  <Typography>
                    This will deselect the following teaching groups:
                  </Typography>
                  {
                    viewableEntities.map(entity => <Typography fontWeight="bold" key={entity.entity_id}>{entity.name}</Typography>)
                  }
                </DialogContent>
              </DeleteAudienceDialog>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.entries(teachingGroupsBySubject).map(([subject, teachingGroups]) => (
              <React.Fragment key={subject}>
                <TableRow className="teaching-group-subject">
                  <TableCell>{subject}</TableCell>
                  <TableCell />
                </TableRow>
                {
                  teachingGroups.map(entity => (
                    <TableRow key={entity.entity_id}>
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <PeopleIcon color="action" />
                          <Typography variant="body2">{entity.name}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small" onClick={() => removeViewableEntitiesCallback([entity])} disabled={viewOnly}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </React.Fragment>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );

  function getTeachingGroupsbySubjects(teachingGroups: Array<TeachingGroup>) {
    return groupBy(teachingGroups, 'subject_description');
  }
}
