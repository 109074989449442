import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { School, Staff, StaffEntityType, StaffAudienceProps } from '../../../../../../../models/FormProps';
import { isEmpty } from '../../../../../../../utils/equality';
import { naturalStringSort, staffSort } from '../../../../../../../utils/sort';
import ScarecrowImage from '../../../../../../../../../assets/images/scarecrow.png';
import SchoolTable from '../../common/SchoolTable';
import PersonTable from '../../common/PersonTable';

interface Props {
  schools: Array<School>;
  viewableAudience: StaffAudienceProps;
  removeViewableEntitiesCallback: (viewableEntities: Array<School | Staff>) => void;
  viewOnly: boolean;
  isHQUser: boolean;
}

export default function SelectedStaffAudience(props: Props): React.ReactElement {
  const { schools, viewableAudience, removeViewableEntitiesCallback, viewOnly, isHQUser } = props;

  return isEmpty(viewableAudience.entities) ? renderNoStaffSelected() : renderSelectedStaffTable();

  function renderNoStaffSelected() {
    return (
      <Stack spacing={2} direction="column" className="audience-editor-no-audience-selected">
        <img className="audience-editor-no-audience-selected-media" alt="" src={ScarecrowImage} />
        <Typography variant="body1">
          You have not selected any audience.
        </Typography>
      </Stack>
    );
  }

  function renderSelectedStaffTable() {
    const entitiesBySchoolID: Map<number, Array<School | Staff>> = viewableAudience.entities.reduce((acc, viewableEntity) => {
      const schoolID = viewableEntity.entity_type === StaffEntityType.SCHOOL
        ? viewableEntity.entity_id
        : (viewableEntity as Staff).school?.entity_id;

      if (!acc.has(schoolID)) {
        acc.set(schoolID, []);
      }

      acc.get(schoolID).push(viewableEntity);
      return acc;
    }, new Map<number, Array<School | Staff>>());

    return (
      <Stack spacing={2} className="audience-editor-selected-audience">
        {isHQUser ? renderTablesBySchool(entitiesBySchoolID) : renderTables(viewableAudience.entities)}
      </Stack>
    );
  }

  function renderTablesBySchool(entitiesBySchoolID: Map<number, Array<School | Staff>>) {
    const schoolsInAudience = schools.filter(school => entitiesBySchoolID.has(school.entity_id)).sort((a, b) => naturalStringSort(a.name, b.name));

    return (
      <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem />}>
        {
          schoolsInAudience.map(school => {
            const schoolName = school.name;
            const entities = entitiesBySchoolID.get(school.entity_id);

            return (
              <Box key={school.entity_id}>
                <Typography className="title">{schoolName}</Typography>
                <Stack spacing={2}>
                  {renderTables(entities)}
                </Stack>
              </Box>
            );
          })
        }
      </Stack>
    );
  }

  function renderTables(entities: Array<School | Staff>) {
    const schoolEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StaffEntityType.SCHOOL) as Array<School>;
    const staffEntities = entities.filter(viewableEntity => viewableEntity.entity_type === StaffEntityType.STAFF) as Array<Staff>;

    return (
      <>
        {schoolEntities.length === 1 && renderSchoolEntity(schoolEntities[0])}
        {staffEntities.length !== 0 && renderStaffEntities(staffEntities)}
      </>
    );
  }

  function renderSchoolEntity(viewableEntity: School) {
    return (
      <SchoolTable
        school={viewableEntity}
        tableHeader="ALL STAFF"
        pluralEntityName="staff"
        viewOnly={viewOnly}
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
      />
    );
  }

  function renderStaffEntities(viewableEntities: Array<Staff>) {
    return (
      <PersonTable
        tableHeader="Staff"
        pluralEntityName="staff"
        viewOnly={viewOnly}
        removeViewableEntitiesCallback={removeViewableEntitiesCallback}
        viewableEntities={viewableEntities.sort((a, b) => staffSort(a, b))}
        getDisplayName={(staff: Staff) => staff.name.toUpperCase()}
        getSecondaryInfo={(staff: Staff) => staff.email}
      />
    );
  }
}
