import { RootElement } from '../form_elements/RootElement';
import { isEmpty, isNullOrUndefined } from '../utils/equality';

export default interface FormProps {
  title: string;
  instructions: string;
  enable_email_copy_of_response: boolean;
  body: RootElement;
  state: PublishStatusProps;
}

export enum PublishStatusProps {
  Published = 'published',
  Unpublished = 'unpublished'
}

export enum AudienceType {
  PUBLIC = 'FormAudience::Public',
  STUDENT = 'FormAudience::Student',
  UNTARGETED_STUDENT = 'FormAudience::UntargetedStudent',
  STAFF = 'FormAudience::Staff',
  UNTARGETED_STAFF = 'FormAudience::UntargetedStaff'
}

export type AudienceProps = PublicAudienceProps | UntargetedStaffAudienceProps | StaffAudienceProps | UntargetedStudentAudienceProps | StudentAudienceProps;

export interface PublicAudienceProps {
  type: AudienceType.PUBLIC;
}

export function newPublicAudienceProps() : PublicAudienceProps {
  return {
    type: AudienceType.PUBLIC
  };
}

export interface UntargetedStaffAudienceProps {
  type: AudienceType.UNTARGETED_STAFF;
}

export function newUntargetedStaffAudienceProps() : UntargetedStaffAudienceProps {
  return {
    type: AudienceType.UNTARGETED_STAFF
  };
}

export interface StaffAudienceProps {
  type: AudienceType.STAFF;
  entities: Array<School | Staff>;
}

export function newStaffAudienceProps(entities?: Array<School | Staff>) : StaffAudienceProps {
  return {
    type: AudienceType.STAFF,
    entities: entities || []
  };
}

export interface UntargetedStudentAudienceProps {
  type: AudienceType.UNTARGETED_STUDENT;
}

export function newUntargetedStudentAudienceProps() : UntargetedStudentAudienceProps {
  return {
    type: AudienceType.UNTARGETED_STUDENT
  };
}

export interface StudentAudienceProps {
  type: AudienceType.STUDENT;
  year: number;
  entities: Array<School | Level | Clazz | TeachingGroup | Student>;
}

export function newStudentAudienceProps({ entities, year }: { entities?: Array<School | Level | Clazz | TeachingGroup | Student>; year?: number }): StudentAudienceProps {
  return {
    type: AudienceType.STUDENT,
    entities: entities || [],
    year: year || workingYear(new Date())
  };
}

export function isUntargetedAudience(
  audience: StaffAudienceProps | UntargetedStaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps
): audience is UntargetedStaffAudienceProps | UntargetedStudentAudienceProps {
  return audience.type === AudienceType.UNTARGETED_STAFF || audience.type === AudienceType.UNTARGETED_STUDENT;
}

function workingYear(date: Date) {
  const month = date.getMonth() + 1; // getMonth() is zero-based
  const year = date.getFullYear();

  return month < 12 ? year : year + 1; // if before Dec, return curr year, else return next year
}

export enum StaffEntityType {
  SCHOOL = 'school',
  STAFF = 'staff'
}

export enum StudentEntityType {
  TEACHING_GROUP = 'teaching_group',
  CLAZZ = 'clazz',
  LEVEL = 'level',
  SCHOOL = 'school',
  STUDENT = 'student'
}

export interface Entity {
  entity_id: number;
  entity_type: StudentEntityType | StaffEntityType;
}

export function isEntityEmpty(entity: Entity): boolean {
  return isEmpty(entity) || isNullOrUndefined(entity.entity_id);
}

export interface School extends Entity {
  name: string;
}

export interface Level extends Entity {
  code: number;
  name?: string;
  school_id: number;
}

export interface Clazz extends Entity {
  name: string;
  level_id: number;
  level_code: number;
  clazz_code: string;
  school_id: number;
}

export interface TeachingGroup extends Entity {
  name: string;
  level_id: number;
  level_code: number;
  school_id: number;
  subject_description: Subject;
}

export type Subject = string;

export interface Student extends Entity {
  name: string;
  level_id: number;
  level_code: number;
  clazz_code: string;
  clazz_id: number;
  clazz_name: string;
  index_number: number;
  school_id: number;
  teaching_group_ids: Set<number>;
}

export interface Staff extends Entity {
  name: string;
  email: string;
  school: School;
}

export interface StudentRoster {
  year: number;
  school: School;
  levels: Array<Level>;
  clazzes: Array<Clazz>;
  teachingGroups: Array<TeachingGroup>;
  students: Array<Student>;
}

export interface StaffRoster {
  school: School;
  staff: Array<Staff>;
}

export interface FormErrors {
  title?: Array<string>;
  instructions?: Array<string>;
  body?: Array<{
    element_id: string;
    errors: FormErrorsByElementAttribute;
  }>;
  bodyByElementID?: FormErrorsByElementID;
  state?: Array<string>;
  audience?: Array<string>;
  audience_metadata?: Array<string>;
  version?: string;
}

export type FormErrorsByElementID = { [elementID: string]: FormErrorsByElementAttribute };

export type FormErrorsByElementAttribute = { [attribute: string]: Array<string> };

export interface FormAnswer {
  question_id: string;
  values: Array<any>;
  errors?: FormAnswerErrorsByAttribute;
}

export type FormAnswerByQnID = { [question_id: string]: FormAnswer };

export type ResponsesByOptionID = { [option_id: string]: any };

export type QuestionOptionsResponsesByQnID = { [question_id: string] : ResponsesByOptionID };

export type LimitedResponsesErrorsByQnID = { [question_id: string] : string };

export type FormAnswerErrorsByAttribute = { [attr: string]: Array<string> };
