import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import AppProvider from 'components/AppProvider';
import FormProps, { AudienceType, FormAnswer, QuestionOptionsResponsesByQnID } from '../../../../models/FormProps';
import { UserMetadata } from '../../../../form_elements/BaseElement';
import TopBar from '../../../../TopBar';
import { isNotEmpty } from '../../../../utils/equality';
import Content from '../../../forms/show/Content';
import { userSort } from '../../../../utils/sort';

interface Props {
  homeUrl: string;
  form: FormProps;
  userMetadata: UserMetadata;
  answers: Array<FormAnswer>;
  questionOptionsResponsesByQnID?: QuestionOptionsResponsesByQnID;
  previewUser: {
    id: string;
    name: string;
  }
  audienceType: AudienceType;
  audience: Array<{
    id: string;
    name: string;
    level_code?: number;
    clazz_code?: string;
    clazz_name?: string;
    index_number?: number;
  }>;
  previewUrl: string;
}

export default function Page(props: Props) {
  const { previewUser, audienceType, audience, previewUrl } = props;
  const [selectedUserID, setSelectedUserID] = useState(previewUser.id);

  return (
    <AppProvider>
      <TopBar {...props} username={previewUser.name}>
        {isNotEmpty(audience) && renderUserSelector()}
      </TopBar>

      <Content {...props} csrfToken="" disableSubmit />
    </AppProvider>
  );

  function renderUserSelector() {
    const sortedAudience = userSort(audienceType, audience);

    return (
      <TextField
        select
        size="small"
        className="user-selector"
        label="Preview as user"
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        value={selectedUserID}
        onChange={onChangePreviewUser}
      >
        <MenuItem key="placeholder" value="">&lt;no user selected&gt;</MenuItem>

        {sortedAudience.map(user => {
          let { name } = user;

          if (audienceType === AudienceType.STUDENT && isNotEmpty(user.clazz_name)) {
            name = `${user.clazz_name} #${user.index_number} ${user.name}`;
          }

          return <MenuItem key={user.id} value={user.id}>{name}</MenuItem>;
        })}
      </TextField>
    );
  }

  function onChangePreviewUser(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const userID = event.target.value;

    setSelectedUserID(userID);
    location.href = previewUrl + '?user=' + userID;
  }
}
