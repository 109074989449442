import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty, isNotEmpty } from '../../../../../utils/equality';
import DialogInstructions from './DialogInstructions';
import PersonaliseAudienceErrorContainer from './PersonaliseAudienceErrorContainer';
import { TableData } from './AutosaveContainer';
import PersonaliseAudienceTable from './PersonaliseAudienceTable';

interface Props {
  viewOnly: boolean;
  tableData: TableData;
  downloadPersonalisedFieldsUrl: string;
  handlePersonalisedFieldsUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: Array<string>;
}

export default function PersonaliseStudentAudience(props: Props) {
  const { viewOnly, tableData, downloadPersonalisedFieldsUrl, handlePersonalisedFieldsUpload, errors } = props;

  if (isEmpty(tableData)) {
    return <CircularProgress className="circular" />;
  }

  return (
    <Box className="audience-editor-personalise-fields-container">
      <Stack spacing={2} className="container-stack">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography className="header-typography" fontWeight="bold">
              Add fields to personalise
            </Typography>
            <DialogInstructions />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              sx={{ width: '39px', minWidth: '0px' }}
              size="small"
              variant="contained"
              aria-label="download"
              disabled={viewOnly}
              href={downloadPersonalisedFieldsUrl}
            >
              <FileDownloadOutlinedIcon />
            </Button>
            <label htmlFor="icon-button-file">
              <input
                id="icon-button-file"
                className="upload-button"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                type="file"
                onChange={handlePersonalisedFieldsUpload}
                disabled={viewOnly}
              />
              <Button
                sx={{ width: '39px', minWidth: '0px' }}
                size="small"
                variant="contained"
                aria-label="upload picture"
                component="span"
                disabled={viewOnly}
              >
                <FileUploadOutlinedIcon />
              </Button>
            </label>
          </Stack>
        </Stack>

        {isNotEmpty(errors) && <PersonaliseAudienceErrorContainer errors={errors} />}

        <PersonaliseAudienceTable tableData={tableData} />
      </Stack>
    </Box>
  );
}
