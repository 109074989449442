/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react';
import Button from '@mui/material/Button';

import { AudienceType, StaffAudienceProps, UntargetedStaffAudienceProps, newUntargetedStaffAudienceProps, newStaffAudienceProps } from '../../../../../models/FormProps';
import { AudienceStage } from '../Content';
import { isNotEmpty } from '../../../../../utils/equality';
import TargetedStaffImage from '../../../../../../../assets/images/targeted_untargeted_selector/targeted-staff.svg';
import UntargetedStaffImage from '../../../../../../../assets/images/targeted_untargeted_selector/untargeted-staff.svg';

interface Props {
  viewableAudience: StaffAudienceProps | UntargetedStaffAudienceProps;
  viewOnly: boolean;
  setActiveStepCallback: (stage: AudienceStage) => void;
  setMaxStepCallback: (stage: AudienceStage) => void;
  updateViewableAudienceCallback: (viewableAudience: StaffAudienceProps | UntargetedStaffAudienceProps) => void;
}

export default function TargetedUntargetedStaffSelector(props: Props): React.ReactElement {
  const { viewableAudience, viewOnly, updateViewableAudienceCallback, setActiveStepCallback, setMaxStepCallback } = props;

  return (
    <>
      <Button
        className={viewableAudience.type === AudienceType.STAFF && isNotEmpty(viewableAudience.entities)
          ? 'audience-editor-button-selected' : 'audience-editor-button'}
        variant="outlined"
        onClick={() => selectTargetedAudienceCallback()}
        disabled={viewOnly}
      >
        <img alt="Specific staff only" src={TargetedStaffImage} className="audience-editor-button-image" />
      </Button>
      <Button
        className={viewableAudience.type === AudienceType.UNTARGETED_STAFF ? 'audience-editor-button-selected' : 'audience-editor-button'}
        variant="outlined"
        onClick={() => selectUntargetedAudienceCallback()}
        disabled={viewOnly}
      >
        <img alt="Any staff" src={UntargetedStaffImage} className="audience-editor-button-image" />
      </Button>
    </>
  );

  function selectTargetedAudienceCallback() {
    if (viewableAudience.type === AudienceType.UNTARGETED_STAFF) {
      if (!confirm('This will restrict the form audience to selected staff. If you have already collected responses, make sure you select those staff in the next page to continue viewing their responses. Click to proceed.')) return;
      updateViewableAudienceCallback(newStaffAudienceProps());
    }

    setMaxStepCallback(AudienceStage.SelectIndividualsOrGroups);
    setActiveStepCallback(AudienceStage.SelectIndividualsOrGroups);
  }

  function selectUntargetedAudienceCallback() {
    if (viewableAudience.type === AudienceType.STAFF) {
      if (isNotEmpty(viewableAudience.entities) && !confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;
      setMaxStepCallback(AudienceStage.SelectedTargetedOrUntargetedAudience);
      updateViewableAudienceCallback(newUntargetedStaffAudienceProps());
    }
  }
}
