import React from 'react';

import Stack from '@mui/material/Stack';
import { AudienceType, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps, StaffAudienceProps, StudentAudienceProps } from '../../../../../models/FormProps';
import { AudienceStage } from '../Content';
import TargetedUntargetedStudentSelector from './TargetedUntargetedStudentSelector';
import TargetedUntargetedStaffSelector from './TargetedUntargetedStaffSelector';
import AudienceGuide from '../common/AudienceGuide';

interface Props {
  viewableAudience: StaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps | UntargetedStaffAudienceProps;
  viewOnly: boolean;
  setActiveStepCallback: (stage: AudienceStage) => void;
  setMaxStepCallback: (stage: AudienceStage) => void;
  updateViewableAudienceCallback: (updated: StaffAudienceProps | StudentAudienceProps | UntargetedStudentAudienceProps | UntargetedStaffAudienceProps) => void;
}

export default function TargetedUntargetedSelector(props: Props): React.ReactElement {
  const { viewableAudience, viewOnly, setActiveStepCallback, setMaxStepCallback, updateViewableAudienceCallback } = props;

  return (
    <Stack spacing={5}>
      <AudienceGuide
        title="Choose who can respond"
      />
      <Stack direction="row" justifyContent="center" spacing={3}>
        {
          viewableAudience.type === AudienceType.STAFF || viewableAudience.type === AudienceType.UNTARGETED_STAFF
            ? (
              <TargetedUntargetedStaffSelector
                viewableAudience={viewableAudience}
                viewOnly={viewOnly}
                setActiveStepCallback={setActiveStepCallback}
                setMaxStepCallback={setMaxStepCallback}
                updateViewableAudienceCallback={updateViewableAudienceCallback}
              />
            )
            : (
              <TargetedUntargetedStudentSelector
                viewableAudience={viewableAudience}
                viewOnly={viewOnly}
                setActiveStepCallback={setActiveStepCallback}
                setMaxStepCallback={setMaxStepCallback}
                updateViewableAudienceCallback={updateViewableAudienceCallback}
              />
            )
        }
      </Stack>
    </Stack>
  );
}
