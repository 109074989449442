import React from 'react';
import { AudienceType } from 'components/models/FormProps';
import { StudentFormResponse } from 'components/pages/sna/manage/forms/responses/Page';
import Responses from 'components/pages/sna/manage/forms/responses/respondents/Responses';
import SociogramContainer from './sociogram/SociogramContainer';

interface TabPanelProps {
  children: JSX.Element;
  index: number;
  value: number;
}

interface Props {
  userID: string;
  formID: string;
  formAudience: AudienceType;
  responseAnswersUrl: string;
  rosterStudentsUrl: string;
  responses: Array<StudentFormResponse>;
  tabValue: number;
  audienceName: string;
  latestResponseDate: string;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  refreshToggle: boolean;
  hasSufficientResponses: boolean;
  feedbackFormUrl: string;
  openFullScreenGraph: boolean;
  onOpenFullScreenGraph: (open: boolean)=> void;
  resourceCentreUrl: string;
}

export default function TabPanels(props: Props) {
  const {
    userID,
    formID,
    tabValue,
    formAudience,
    responses,
    responseAnswersUrl,
    rosterStudentsUrl,
    audienceName,
    latestResponseDate,
    onTabChange,
    refreshToggle,
    hasSufficientResponses,
    feedbackFormUrl,
    openFullScreenGraph,
    onOpenFullScreenGraph,
    resourceCentreUrl
  } = props;

  return (
    <>
      <TabPanel value={tabValue} index={0}>
        <Responses formAudience={formAudience} responses={responses} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SociogramContainer
          userID={userID}
          formID={formID}
          responseAnswersUrl={responseAnswersUrl}
          rosterStudentsUrl={rosterStudentsUrl}
          audienceName={audienceName}
          latestResponseDate={latestResponseDate}
          onTabChange={onTabChange}
          refreshToggle={refreshToggle}
          hasSufficientResponses={hasSufficientResponses}
          feedbackFormUrl={feedbackFormUrl}
          openFullScreenGraph={openFullScreenGraph}
          onOpenFullScreenGraph={onOpenFullScreenGraph}
          resourceCentreUrl={resourceCentreUrl}
        />
      </TabPanel>
    </>
  );
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && children}
    </div>
  );
}
