import React, { Fragment } from 'react';
import Box from '@mui/system/Box';
import { DEFAULT_SECTION_TEXT } from 'components/utils/default_text';
import { BaseElement, UnionElement, renderElement, RenderElementEditMode, RenderElementAnswerMode, newElement, renderHeader, newBaseElement } from '../BaseElement';
import { Draggable } from '../Draggable';
import { Selectable } from '../Selectable';
import { isEmpty, isNotEmpty } from '../../utils/equality';
import { DropZone } from '../DropZone';
import { ImageElementType } from '../image/ImageElement';
import { defaultDisplayConditionsContainer, DisplayConditionsContainer } from '../display_condition/DisplayConditionsContainer';
import { modifyAnchorTagBehaviour, sanitizeHtmlInString } from '../../utils/html';

export const SectionElementType = 'FormElement::Section';

export interface SectionElement extends BaseElement {
  title: string;
  children: Array<UnionElement>;
  display_conditions_container: DisplayConditionsContainer;
}

export function newSectionElement(props?: SectionElement) {
  const { title, children, display_conditions_container } = props || {};

  let clonedChildren = [];
  if (isNotEmpty(children)) {
    clonedChildren = children.filter(child => child.type !== ImageElementType).map(child => newElement(child.type, child));
  }

  return {
    ...newBaseElement(),
    type: SectionElementType,
    title: (title != null) ? title : DEFAULT_SECTION_TEXT,
    children: clonedChildren,
    display_conditions_container: display_conditions_container || defaultDisplayConditionsContainer()
  };
}

interface SectionElementComponentProps {
  element: SectionElement;
  sectionNum: number;
  questionIDs: Array<string>;
  imageIDs: Array<string>;
  numErrors: number;

  editMode?: RenderElementEditMode;
  answerMode?: RenderElementAnswerMode;
}

export function SectionElementComponent(props: SectionElementComponentProps) {
  const { element, sectionNum, questionIDs, imageIDs, numErrors, editMode, answerMode } = props;

  const sanitizedTitle = sanitizeHtmlInString(element.title);
  const modifiedTitle = modifyAnchorTagBehaviour(sanitizedTitle);

  return (
    <Box className="form-section-element">
      <Box className="header-group">
        {renderHeader(`SECTION ${sectionNum}`, false, numErrors, isNotEmpty(element.display_conditions_container.display_conditions), isEmpty(answerMode))}
        {/* eslint-disable-next-line react/no-danger -- HTML string is sanitised. */}
        <p dangerouslySetInnerHTML={{ __html: modifiedTitle }} />
      </Box>

      {isEmpty(editMode) ? renderChildrenAnswerMode() : renderChildrenEditMode()}

      <Box className="tail" />
    </Box>
  );

  function renderChildrenAnswerMode() {
    return (
      <Box className="children">
        {element.children.map((child, index) => (
          <Fragment key={index}>
            {renderElement(child, editMode, answerMode, questionIDs, [], imageIDs)}
          </Fragment>
        ))}
      </Box>
    );
  }

  function renderChildrenEditMode() {
    return (
      <Box className="children">
        <DropZone parentID={element.id} parentElementType={element.type} index={0} onDragTo={editMode.onReorderElement} />

        {element.children.map((child, index) => {
          const isElementSelected = editMode.selectedElementID === child.id;
          return (
            <Fragment key={index}>
              <Draggable
                elementID={child.id}
                elementType={child.type}
                parentID={element.id}
                elementIndex={index}
                disabled={editMode.viewOnly}
              >
                <Selectable
                  ref={isElementSelected ? editMode.selectedElementRefCallback : null}
                  elementID={child.id}
                  isSelected={isElementSelected}
                  onSelect={editMode.onSelectElement}
                >
                  {renderElement(child, editMode, answerMode, questionIDs, [], imageIDs)}
                </Selectable>
              </Draggable>

              <DropZone parentID={element.id} parentElementType={element.type} index={index + 1} onDragTo={editMode.onReorderElement} />
            </Fragment>
          );
        })}
      </Box>
    );
  }
}

export function findElementInSection(section: SectionElement, elementID: string) {
  for (let child of section.children) {
    if (child.id === elementID) return child;
  }

  return null;
}

export function insertElementInSection(section: SectionElement, index: number, element: UnionElement): SectionElement {
  const children = [...section.children];
  children.splice(index, 0, element);

  return { ...section, children };
}

export function updateElementInSection(section: SectionElement, element: UnionElement): SectionElement {
  const children = [...section.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === element.id) {
      children[i] = element;
      break;
    }
  }

  return { ...section, children };
}

export function cloneElementInSection(section: SectionElement, element: UnionElement): {
  newSection: SectionElement;
  clone: UnionElement;
} {
  const clone = newElement(element.type, element);
  const { children } = section;

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === element.id) {
      const newSection = insertElementInSection(section, i + 1, clone);
      return { newSection, clone };
    }
  }

  return { newSection: section, clone: null };
}

export function deleteElementInSection(section: SectionElement, elementID: string): SectionElement {
  const children = [...section.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === elementID) {
      children.splice(i, 1);
      break;
    }
  }

  return { ...section, children };
}
