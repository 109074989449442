import React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import { ElementComponentProps, isElementComponentPropsEqual, newElementID, renderHeader } from '../../BaseElement';
import { BaseQuestion, newBaseQuestion, QuestionTypePrefix, renderQuestionText, extractErrorsIfPresent } from '../BaseQuestion';
import { isEmpty, isNotEmpty, isNonEmptyObject } from '../../../utils/equality';

export const MultipleResponseQuestionType = `${QuestionTypePrefix}::Mrq`;
const MultipleResponseQuestionOptionType = `${MultipleResponseQuestionType}::Option`;

export interface MultipleResponseQuestion extends BaseQuestion {
  options: Array<MultipleResponseQuestionOption>;
  min_options: number;
  max_options: number;
}

export interface MultipleResponseQuestionOption {
  id: string;
  type: string;
  value: string;
}

export function newMultipleResponseQuestion(element?: MultipleResponseQuestion) {
  const { options, min_options, max_options } = element || {};
  const newOptions = isEmpty(options)
    ? Array(3).fill(null).map((_, i) => newMultipleResponseQuestionOption({ id: null, type: null, value: `Option ${i + 1}` }))
    : options.map(option => newMultipleResponseQuestionOption({ id: null, type: option.type, value: option.value }));

  const props = {
    ...newBaseQuestion(element),
    type: MultipleResponseQuestionType,
    options: newOptions,
    min_options: min_options || 1,
    max_options: max_options || 3
  };

  if (props.max_options === 0) props.max_options = props.options.length;
  return props;
}

export function newMultipleResponseQuestionOption(props?: MultipleResponseQuestionOption) {
  const { id, value } = props || {};

  return {
    id: id || newElementID(),
    type: MultipleResponseQuestionOptionType,
    value: value || 'Option'
  };
}

export const MultipleResponseQuestionComponent = React.memo(multipleResponseQuestionComponent, isElementComponentPropsEqual);

export function multipleResponseQuestionComponent(props: ElementComponentProps<MultipleResponseQuestion>) {
  const { element, numErrors, answerMode, qnNum } = props;

  const hasAnswerErrors = isNonEmptyObject(answerMode?.answer?.errors);

  return (
    <Box className={classNames('form-element', 'form-multiple-response-question', { 'form-element-error': hasAnswerErrors })}>
      {renderHeader(`QUESTION ${qnNum} - MULTIPLE RESPONSE`, element.required, numErrors, isNotEmpty(element.display_conditions_container.display_conditions), isEmpty(answerMode))}
      {renderQuestionText(element.text)}

      {renderOptions()}
    </Box>
  );

  function renderOptions() {
    return (
      <FormControl error={hasAnswerErrors}>
        <FormGroup className="answer">
          {element.options.map(option => (
            <FormControlLabel
              className="option-container"
              key={option.id}
              control={(
                <Checkbox
                  className="option"
                  value={option.id}
                  checked={answerMode?.answer?.values?.includes(option.id) || false}
                  onChange={onChangeCheckboxOption}
                />
              )}
              label={option.value}
            />
          ))}
        </FormGroup>
        <FormHelperText className="form-helper-text">{extractErrorsIfPresent(answerMode?.answer?.errors)}</FormHelperText>
      </FormControl>
    );
  }

  function onChangeCheckboxOption(event: React.ChangeEvent<HTMLInputElement>) {
    if (isEmpty(answerMode)) return;

    const { value } = event.target;
    const valueSet = new Set(answerMode.answer.values);
    valueSet.has(value) ? valueSet.delete(value) : valueSet.add(value);

    const values = Array.from(valueSet);
    answerMode.onChangeAnswer({ ...answerMode.answer, values });
  }
}
