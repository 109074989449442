/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import QRCode from 'qrcode';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { PublishStatusProps } from '../../../../models/FormProps';
import { getCsrfToken } from '../../../../utils/csrf';

interface ShareModalProps {
  formName: string;
  formUrl: string;
  createAnnouncementOnPgUrl: string;
  publishStatus: PublishStatusProps;
  enableEmailCopyOfResponse: boolean;
  onChangePublishStatus: (status: PublishStatusProps) => void;
  onChangeEnableEmailCopyOfResponse: (enableEmailCopyOfResponse: boolean) => void;
  onCloseModal: () => void;
  viewOnly: boolean;
  isShareableToPG: boolean;
}

export function ShareModal(props: ShareModalProps): React.ReactElement {
  const {
    formName,
    formUrl,
    createAnnouncementOnPgUrl,
    publishStatus,
    enableEmailCopyOfResponse,
    onChangePublishStatus,
    onChangeEnableEmailCopyOfResponse,
    onCloseModal,
    viewOnly,
    isShareableToPG
  } = props;

  const [qrCode, setQRCode] = useState(null);
  const [isSendingToPG, setIsSendingToPG] = useState(false);
  const [pgErrorMessage, setPGErrorMessage] = useState('');
  const qrCodeRef = useRef<HTMLAnchorElement>();

  useEffect(() => {
    QRCode.toDataURL(formUrl, { width: 500, scale: 8 }, (err, url) => {
      if (url) setQRCode(url);
    });
  }, []);

  return (
    <Dialog open onClose={onCloseModal}>
      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h5" fontSize="24px" fontWeight="600">Publish</Typography>
            <Typography fontSize="16px">To allow your audience to view and respond to your form</Typography>
          </Stack>

          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={(
                  <Switch
                    checked={publishStatus === PublishStatusProps.Published}
                    onChange={onChangePublishStatusSwitch}
                    disabled={viewOnly}
                  />
                )}
                label={publishStatus === PublishStatusProps.Published ? 'Published & accepting responses' : 'Unpublished & not accepting responses'}
                componentsProps={{ typography: { fontWeight: '600', fontSize: '16px' } }}
              />
            </FormGroup>
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={!enableEmailCopyOfResponse}
                  onChange={onChangeEnableEmailCopyOfResponseCheckbox}
                  disabled={publishStatus === PublishStatusProps.Unpublished}
                />
              )}
              label="Disable email response receipts for respondents"
              componentsProps={{ typography: { fontSize: '16px' } }}
            />
          </FormGroup>
        </Stack>

        <Divider sx={{ marginTop: '32px', marginBottom: '32px' }} />

        <Stack spacing={3}>
          <Typography variant="h5" fontSize="24px" fontWeight="600">Share</Typography>

          {isShareableToPG && renderShareOnPG()}

          {isShareableToPG && <Typography variant="h6" fontSize="24px" fontWeight="600">or</Typography>}

          <Box>
            <Typography fontSize="16px">Direct form link or QR code</Typography>

            <Stack direction="row" alignItems="center">
              <TextField fullWidth size="small" value={formUrl} />
              <Button variant="contained" size="large" sx={{ marginLeft: '15px', marginRight: '5px' }} onClick={onClickCopy}>COPY</Button>
              {qrCode && (
                <a ref={qrCodeRef} href={qrCode} download={`${formName} QR.png`}>
                  <img alt="QR code for this form" src={qrCode} width="50" />
                </a>
              )}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  function onChangePublishStatusSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onChangePublishStatus(checked ? PublishStatusProps.Published : PublishStatusProps.Unpublished);
  }

  function onChangeEnableEmailCopyOfResponseCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    onChangeEnableEmailCopyOfResponse(!event.target.checked);
  }

  function onClickCopy() {
    window.navigator.clipboard.writeText(formUrl);
  }

  function renderShareOnPG() {
    return (
      <Box>
        <Typography marginBottom="8px" fontSize="16px">As Announcement on Parents Gateway</Typography>
        <Button
          variant="outlined"
          size="large"
          disabled={isSendingToPG}
          endIcon={isSendingToPG ? <CircularProgress color="inherit" size={20} /> : <OpenInNewIcon />}
          onClick={shareOnPg}
        >
          SHARE ON PARENTS GATEWAY
        </Button>
        {pgErrorMessage && <FormHelperText error>{pgErrorMessage}</FormHelperText>}
      </Box>
    );
  }

  function shareOnPg() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      }
    };

    setIsSendingToPG(true);
    setPGErrorMessage('');

    return fetch(createAnnouncementOnPgUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.link) {
          window.open(data.link);
        } else {
          setPGErrorMessage(data.error);
        }
      })
      .finally(() => setIsSendingToPG(false));
  }
}
