import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import GroupsIcon from '@mui/icons-material/Groups';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import Snackbar from '@mui/material/Snackbar';
import { getCsrfToken } from 'components/utils/csrf';
import { isEmpty } from 'components/utils/equality';
import {
  FormErrors,
  PublishStatusProps
} from 'components/models/FormProps';
import { Box, Typography } from '@mui/material';
import FormMenu from './FormMenu';
import { FormData } from '../Page';

interface Props {
  data: Array<FormData>;
}

interface FormError {
  formID: string;
  errors?: FormErrors;
}

export default function FormsTable(props: Props) {
  const { data } = props;
  const [csrfTokenState, setCsrfTokenState] = useState(getCsrfToken());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [formsByID, setFormsByID] = useState(
    data.reduce((acc, row) => {
      acc[row.formID] = row;
      return acc;
    }, {})
  );
  const [formError, setFormError] = useState<FormError | null>(null);

  return (
    <Paper className="sna-manage-forms-index-page-forms-table">
      <TableContainer className="table-container">
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="isPublished"><Typography variant="caption">Publish</Typography></TableCell>
              <TableCell key="formTitle"><Typography variant="caption">Form Title</Typography></TableCell>
              <TableCell key="lastModified"><Typography variant="caption">Last Modified</Typography></TableCell>
              <TableCell key="formCreator"><Typography variant="caption">Created By</Typography></TableCell>
              <TableCell key="menu" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => renderRow(row))}
          </TableBody>
        </Table>
      </TableContainer>
      {renderPagination(data.length)}

      <Snackbar
        open={!!formError}
        onClose={() => setFormError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {renderError()}
      </Snackbar>
    </Paper>
  );

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handlePublishToggle(event: React.ChangeEvent<HTMLInputElement>) {
    updateForm(event);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  }

  async function updateForm(event: React.ChangeEvent<HTMLInputElement>) {
    const formID = event.target.name;
    const form = formsByID[formID];
    const state = event.target.checked ? PublishStatusProps.Published : PublishStatusProps.Unpublished;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfTokenState
      },
      body: JSON.stringify({
        form: {
          state,
          lock_version: form.version
        }
      })
    };

    try {
      const response = await fetch(form.updateFormUrl, requestOptions);
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }

      const data = await response.json();
      if (data) {
        setCsrfTokenState(data.csrf_token);
        if (!data.success) {
          setFormError({ formID, errors: data.errors });
          return;
        }

        const updatedFormsByID: { [key: string] : FormData } = { ...formsByID };
        updatedFormsByID[formID].version = data.version;
        updatedFormsByID[formID].isPublished = PublishStatusProps.Published === data.state;
        setFormsByID(updatedFormsByID);
      }
    } catch (e) {
      setFormError({ formID });
      console.log(e);
    }
  }

  function renderRow(row: FormData) {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };

    return (
      <TableRow hover role="checkbox" key={row.formID} tabIndex={-1}>
        <TableCell>
          <Switch
            size="small"
            checked={formsByID[row.formID].isPublished}
            onChange={e => handlePublishToggle(e)}
            disabled={row.viewOnly}
            color="success"
            name={row.formID}
          />
        </TableCell>
        <TableCell>
          <Typography variant="tableBody" fontWeight="bold"><Link href={row.editFormUrl}>{row.formTitle}</Link></Typography>
          {renderTooltip(row)}
        </TableCell>
        <TableCell>
          <Typography variant="tableBody">
            {new Date(row.lastModified).toLocaleString('en-SG', options)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="tableBody">
            {row.formCreator}
          </Typography>
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="flex-end">
            <FormMenu deleteFormUrl={row.deleteFormUrl} duplicateFormUrl={row.duplicateFormUrl} deleteCollaboratorAccessUrl={row.deleteCollaboratorAccessUrl} isFormCreator={row.isFormCreator} />
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  function renderTooltip(row: FormData) {
    if (row.hasCollaborators) {
      const tip = row.isFormCreator ? 'You have shared this form with others.' : 'This form is shared with you.';
      return (
        <Tooltip title={tip}>
          <IconButton>
            <GroupsIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    }
  }

  function renderPagination(numRows: number) {
    if (numRows) {
      return (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    }
  }

  function renderError() {
    if (!formError) return;

    const { formTitle } = formsByID[formError.formID];
    const { version: versionError } = formError.errors;
    const errorMessage = isEmpty(versionError) ? 'An unexpected error occurred.' : versionError;
    return (
      <Alert severity="error">{`Could not update '${formTitle}': ${errorMessage}`}</Alert>
    );
  }
}
