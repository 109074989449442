import { Box, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import FormProps, { FormErrors } from '../../../../models/FormProps';
import { FormTemplate } from '../../../../models/FormTemplate';
import Form from '../../forms/edit_questions/form/Form';
import Editor from '../../forms/edit_questions/editor/Editor';

interface Props {
  formTemplate: FormTemplate;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
}

export default function Content(props: Props): React.ReactElement {
  const { formTemplate, metadata, metadataValuesWithHeaderUrl } = props;

  const theme = useTheme();
  const [selectedElementID, setSelectedElementID] = useState<string>(null);

  const form = {
    title: formTemplate.title,
    instructions: formTemplate.formInstructions,
    body: formTemplate.body
  } as FormProps;

  return (
    <Box
      component="main"
      className="manage-forms-edit-page-content manage-forms-edit-questions-page-content"
      height="100%"
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        paddingTop={theme.spacing(2)}
        height={`calc(100vh - 100px - ${theme.spacing(2)})`} // minus 100px for topbar and theme.spacing(2) for padding
      >
        <Form
          form={form}
          template={{
            usageInstructions: formTemplate.usageInstructions,
            userGuideUrl: formTemplate.userGuideUrl
          }}
          formErrors={{} as FormErrors}
          selectedElementID={selectedElementID}
          selectedElementRefCallback={null}
          onSelectElement={setSelectedElementID}
          onReorderElement={() => {}}
          viewOnly
        />

        <Editor
          form={form}
          formErrors={{} as FormErrors}
          metadata={metadata}
          metadataValuesWithHeaderUrl={metadataValuesWithHeaderUrl}
          viewOnly
          updateFormImageUrl={null}
          selectedElementID={selectedElementID}
          onChangeTitle={() => {}}
          onChangeInstructions={() => {}}
          onUpdateElement={() => {}}
          onCloneElement={() => {}}
          onDeleteElement={() => {}}
          onNewElement={() => {}}
        />
      </Stack>
    </Box>
  );
}
