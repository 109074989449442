import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'components/utils/equality';
import { AudienceType } from 'components/models/FormProps';
import { StudentFormResponse } from '../../Page';
import SunflowerImage from '../../../../../../../../../assets/images/sna/sunflowers/sunflower.svg';
import { getFormResponsesPercent, getFormResponsesRate, groupResponsesByGroupName, studentResponsesSort } from '../Responses';

interface Props {
  formAudience: AudienceType;
  responses: Array<StudentFormResponse >;
}

export default function TargetedAudienceContent(props: Props) {
  const { responses, formAudience } = props;
  const [selectedRow, setSelectedRow] = useState(''); // Refers to the rows in the responses table.
  const responsesByGroup: { [key: string]: Array<StudentFormResponse> } = groupResponsesByGroupName(formAudience, responses);
  const nonRespondents = selectedRow ? responsesByGroup[selectedRow].filter(r => !r.hasSubmitted) : [];

  return (
    <>
      <Typography className="content-title" variant="h5">{`Respondents (${getFormResponsesPercent(responses)})`}</Typography>
      <Typography className="content-description">View non-respondents by clicking on a group</Typography>
      <Grid container spacing={4}>
        <Grid item xs={6} className="content-grid">
          {renderResponsesTable()}
        </Grid>
        <Grid item xs={6} className="content-grid">
          {renderNonRespondentsContent()}
        </Grid>
      </Grid>
    </>
  );

  function renderResponsesTable() {
    let groupName;

    if (formAudience === AudienceType.STUDENT) {
      if (responses.length === 0) {
        groupName = 'Classes';
      } else {
        const response = responses[0] as StudentFormResponse;
        groupName = response.groupName !== response.className ? 'Schools' : 'Classes';
      }
    }

    return (
      <TableContainer className="content-grid-responses-summary-table" component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="white">
                  {groupName}
                </Typography>
              </TableCell>
              <TableCell><Typography color="white">Responses</Typography></TableCell>
              <TableCell><Typography color="white">Progress</Typography></TableCell>
              <TableCell><Typography color="white">Non-Respondents</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.entries(responsesByGroup).map(([groupName, responses]) => {
                const groupToDisplay = groupName === 'null' ? 'Not Applicable' : groupName;

                return (
                  <TableRow
                    key={groupName}
                    selected={groupName === selectedRow}
                    hover
                    onClick={e => handleClickOnGroup(e, groupName)}
                  >
                    <TableCell><Typography>{groupToDisplay}</Typography></TableCell>
                    <TableCell><Typography>{getFormResponsesRate(responses)}</Typography></TableCell>
                    <TableCell><Typography>{getFormResponsesPercent(responses)}</Typography></TableCell>
                    <TableCell><Typography>{responses.filter(r => !r.hasSubmitted).length}</Typography></TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderNonRespondentsContent() {
    return selectedRow === '' ? renderNoGroupSelected() : renderNonRespondents();
  }

  function renderNonRespondents() {
    return (
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader aria-label="non-respondents-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight="700">Non-Respondents</Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton size="small" onClick={() => window.navigator.clipboard.writeText(nonRespondents.map(person => person.name).join('\n'))}>
                  <ContentCopyIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderNonRespondentsRows()}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderNonRespondentsRows() {
    const sorted = (nonRespondents as Array<StudentFormResponse>).sort((a, b) => studentResponsesSort(a, b));

    return sorted.map(person => {
      const nameToDisplay = isEmpty(person.indexNumber) ? person.name : `${person.indexNumber}. ${person.name}`;

      return (
        <TableRow key={person.name}>
          <TableCell>
            <Typography variant="caption">
              {nameToDisplay}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="caption">
              {person.className}
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  }

  function renderNoGroupSelected() {
    return (
      <Stack spacing={2} className="content-grid-no-group-selected">
        <img className="content-grid-no-group-selected-media" alt="" src={SunflowerImage} />
        <Typography fontWeight="bold" fontStyle="italic" align="center">
          &quot;These petals remind me of different friends working together to form the structure of a sunflower.&quot;
        </Typography>
        <Typography align="center">
          - Student M, Sec 1
        </Typography>
      </Stack>
    );
  }

  function handleClickOnGroup(event: React.MouseEvent<unknown>, key: string) {
    setSelectedRow(key);
  }
}
