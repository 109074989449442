import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { School } from 'components/models/FormProps';
import DeleteAudienceDialog from './DeleteAudienceDialog';

interface Props {
  removeViewableEntitiesCallback: (viewableEntities: Array<School>) => void;
  viewOnly: boolean;
  tableHeader: string;
  pluralEntityName: string;
  school: School;
}

export default function SchoolTable(props: Props): React.ReactElement {
  const { tableHeader, pluralEntityName, school, removeViewableEntitiesCallback, viewOnly } = props;

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="Selected school">
        <TableBody>
          <TableRow>
            <TableCell>
              <Stack direction="row" spacing={1} alignItems="center">
                <PeopleIcon color="action" />
                <Typography variant="body2">{tableHeader}</Typography>
              </Stack>
            </TableCell>
            <TableCell align="right">
              <DeleteAudienceDialog
                icon={<DeleteIcon fontSize="small" />}
                viewOnly={viewOnly}
                removeAudienceCallback={() => removeViewableEntitiesCallback([school])}
              >
                <DialogTitle>{`Remove all ${pluralEntityName}?`}</DialogTitle>
                <DialogContent>
                  <Typography>
                    {`This will deselect all ${pluralEntityName} in ${school.name}`}
                  </Typography>
                </DialogContent>
              </DeleteAudienceDialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
