import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

interface Props {
  title: string;
}

export default function AudienceGuide(props: Props): React.ReactElement {
  const { title } = props;
  const [open, setOpen] = useState(false);

  return (
    <Stack spacing={1} alignItems="center" textAlign="center">
      <Typography variant="h5" fontWeight="bold" className="audience-guide-header">
        {title}
      </Typography>
      <Box className="audience-guide-subtitle">
        <Typography variant="subtitle1">
          For school staff, please note that only responses <strong>from your school</strong> will be visible to you.
        </Typography>
        <Typography variant="subtitle1">
          To collaborate across schools, <Link className="audience-guide-link" style={{ cursor: 'pointer' }} onClick={handleClickOpen}>learn more</Link>.
        </Typography>
        {renderDialog()}
      </Box>
    </Stack>
  );

  function renderDialog() {
    return (
      <Dialog className="audience-guide-dialog" open={open} onClose={handleClose}>
        <Stack maxWidth="480px" paddingX={3} paddingY={2}>
          <Stack spacing={4}>
            <Typography variant="subtitle1" className="typography">
              For cross-school collaboration i.e. requiring students/staff from schools with different school code to respond to your form,{' '}
              you may want to <strong>add a staff from the partner school as collaborator</strong>.
            </Typography>
            <Typography variant="subtitle1" className="typography">
              This will give them access to their school&apos;s responses on your behalf <strong>as responses from other schools will not be visible to you</strong>.
            </Typography>
            <DialogActions>
              <Button variant="contained" onClick={handleClose}>
                Got it
              </Button>
            </DialogActions>
          </Stack>
        </Stack>
      </Dialog>
    );
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
}
