import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TopBar from '../../../TopBar';

export enum TabIndex {
  Audience = 0,
  Questions,
  Responses,
  Collaborators
}

interface Props {
  formsUrl: string;
  username: string;
  logOutUrl: string;
  tab: TabIndex;
  setTab: (tab: TabIndex) => void;
}

export default function EditTopBar(props: Props) {
  const { formsUrl, tab, setTab } = props;

  return (
    <TopBar homeUrl={formsUrl} {...props}>
      <Tabs className="tabs" value={tab} onChange={(_event, value) => setTab(value)}>
        <Tab label="Audience" />
        <Tab label="Questions" />
        <Tab label="Responses" />
        <Tab label="Collaborators" />
      </Tabs>
    </TopBar>
  );
}
