import React, { useRef, useCallback, useEffect, MutableRefObject } from 'react';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts';
import { toBlob, toPng } from 'html-to-image';

import StarIcon from '@mui/icons-material/Star';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { FormAnswer } from '../../../models/FormProps';
import { MultipleChoiceQuestion, MultipleChoiceQuestionOption } from './MultipleChoiceQuestion';
import { groupByOne } from '../../../utils/group_by';
import { isEmpty, isNotEmpty } from '../../../utils/equality';
import { modifyAnchorTagBehaviour, sanitizeHtmlInString } from '../../../utils/html';
import { FileToDownload } from '../../../pages/manage/forms/responses/Content';

interface AnswerData {
  value: string;
  count: number;
}

interface Props {
  functionsToDownloadAllChartImagesRef: MutableRefObject<Array<() => Promise<FileToDownload>>>;
  qnNum: number;
  question: MultipleChoiceQuestion;
  answers: Array<FormAnswer>;
}

const MAX_LABEL_LENGTH = 17;

export default function MultipleChoiceQuestionAnalysis(props: Props) {
  const { question, qnNum, answers, functionsToDownloadAllChartImagesRef } = props;
  const sanitizedText = sanitizeHtmlInString(question.text);
  const modifiedText = modifyAnchorTagBehaviour(sanitizedText);
  const submittedAnswers = answers.filter(answer => isNotEmpty(answer.values));
  const answersData: Array<AnswerData> = generateAnswersData(question, submittedAnswers);

  const downloadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    functionsToDownloadAllChartImagesRef.current.push(onDownloadAllChartImagesButtonClick);
  }, []);

  const onDownloadButtonClick = useCallback(() => {
    if (downloadRef.current === null) {
      return;
    }

    toPng(downloadRef.current, { cacheBust: true, backgroundColor: 'white' })
      .then(dataUrl => {
        const link = document.createElement('a');
        link.download = `Q${qnNum}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(err => {
        console.log(err);
      });
  }, [downloadRef]);

  return (
    <Stack ref={downloadRef} spacing={2} component={Paper} padding={2} width="650px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Typography className="form-question-type" variant="subtitle2">{`QUESTION ${qnNum} - MULTIPLE CHOICE`}</Typography>
          {question.required && <StarIcon className="required" fontSize="small" />}
          {isNotEmpty(question.display_conditions_container.display_conditions) && <AltRouteIcon />}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{submittedAnswers.length === 1 ? '1 response' : `${submittedAnswers.length} responses`}</Typography>
          <IconButton size="small" aria-label="download" onClick={onDownloadButtonClick}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
      {/* eslint-disable-next-line react/no-danger -- HTML string is sanitised. */}
      <p className="form-question-text" dangerouslySetInnerHTML={{ __html: modifiedText }} />
      <Divider />
      {submittedAnswers.length !== 0 && renderChart()}
    </Stack>
  );

  function renderChart() {
    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={answersData} layout="vertical" margin={{ top: 15, right: 30, left: 30, bottom: 15 }}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis type="number" allowDecimals={false} />
          <YAxis
            tickFormatter={label => (label.length > MAX_LABEL_LENGTH ? `${label.substr(0, MAX_LABEL_LENGTH)}...` : label)}
            dataKey="value"
            type="category"
            interval={0}
            width={150}
          />
          <Tooltip />
          <Bar dataKey="count" fill="#00B7B5" isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  function generateAnswersData(question: MultipleChoiceQuestion, answers: Array<FormAnswer>) {
    const optionsByID: { [key: string]: MultipleChoiceQuestionOption } = groupByOne(question.options, option => option.id);

    const answersDataByValue: { [key: string]: AnswerData } = question.options.reduce((acc, o) => {
      acc[o.value] = { value: o.value, count: 0 };
      return acc;
    }, {});

    answers.forEach(answer => {
      answer.values.forEach(answerID => {
        if (isEmpty(answerID)) return;

        const { value } = optionsByID[answerID];
        answersDataByValue[value].count += 1;
      });
    });

    return Object.values(answersDataByValue);
  }

  function onDownloadAllChartImagesButtonClick() {
    const fileName = `Q${qnNum}.png`;
    return toBlob(downloadRef.current, { cacheBust: true, backgroundColor: 'white' })
      .then(blob => ({ fileName, data: blob } as FileToDownload));
  }
}
