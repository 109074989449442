import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { School, StaffAudienceProps, Staff, StaffRoster } from 'components/models/FormProps';
import { isNotEmpty, isEmpty } from 'components/utils/equality';
import { staffSort } from 'components/utils/sort';
import StaffAudienceGroupSelectorContainer from './StaffAudienceGroupSelectorContainer';
import SchoolSelector from '../../SchoolSelector';

interface Props {
  school: School;
  schools: Array<School>;
  viewableAudience: StaffAudienceProps;
  addViewableEntitiesCallback: (viewableEntities: Array<School | Staff>) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  getStaffRosterUrl: string;
}

export default function StaffAudienceSelector(props: Props): React.ReactElement {
  const { school, schools, viewableAudience, addViewableEntitiesCallback, viewOnly, isHQUser, getStaffRosterUrl } = props;
  const [selectedSchoolID, setSelectedSchoolID] = useState<number>(school?.entity_id);
  const [staffRoster, setStaffRoster] = useState<StaffRoster>(null);

  useEffect(() => {
    if (isNotEmpty(selectedSchoolID)) getRostersBySchoolID();
  }, [selectedSchoolID]);

  return (
    <Stack spacing={2} component={Paper} className="audience-editor-audience-selector">
      {isEmpty(selectedSchoolID) && isHQUser ? renderSchoolsSelector() : renderAudienceGroupSelector()}
    </Stack>
  );

  function renderSchoolsSelector() {
    if (isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <SchoolSelector
        schools={schools}
        viewOnly={viewOnly}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        setSchoolIDCallback={setSelectedSchoolID}
      />
    );
  }

  function renderAudienceGroupSelector() {
    if (isEmpty(staffRoster)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <StaffAudienceGroupSelectorContainer
        viewableAudience={viewableAudience}
        staffRoster={staffRoster}
        isHQUser={isHQUser}
        viewOnly={viewOnly}
        addViewableEntitiesCallback={addViewableEntitiesCallback}
        setSchoolIDCallback={setSelectedSchoolID}
      />
    );
  }

  function getRostersBySchoolID() {
    setStaffRoster(null);

    const url = new URL(getStaffRosterUrl);
    url.search = new URLSearchParams({ school_id: `${selectedSchoolID}` }).toString();

    const requestOptions = {
      method: 'GET'
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          const roster: StaffRoster = data.staff_roster;
          roster.staff.sort((a, b) => staffSort(a, b));

          setStaffRoster(data.staff_roster);
        }
      });
  }
}
