import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

interface FormPublishedStatusProps {
  isPublished: boolean;
}

export default function FormPublishedStatus({ isPublished }: FormPublishedStatusProps): React.ReactElement {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        height={theme.spacing(1)}
        width={theme.spacing(1)}
        bgcolor={isPublished ? theme.palette.success.main : theme.palette.text.disabled}
        borderRadius="50%"
      />
      <Typography variant="caption">
        {isPublished ? 'Published' : 'Not published'}
      </Typography>
    </Stack>
  );
}
