import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HTMLForm from 'components/pages/common/HTMLForm';
import React, { useState } from 'react';
import { GoogleAnalyticsEvent, logGoogleAnalyticsEvent } from '../../../../../utils/ga';

interface Props {
  deleteFormUrl: string,
  duplicateFormUrl: string,
  deleteCollaboratorAccessUrl: string,
  isFormCreator: boolean,
  publishSettings?: {
    toggleIsPublished: () => void;
    isPublished: boolean;
    isViewOnly: boolean;
  }
  menuButtonSize?: IconButtonProps['size'];
}

export default function FormMenu(props: Props) {
  const { deleteFormUrl, duplicateFormUrl, deleteCollaboratorAccessUrl, isFormCreator, publishSettings, menuButtonSize = 'small' } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
        size={menuButtonSize}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <HTMLForm action={duplicateFormUrl} method="POST">
          {({ onSubmit }) => (
            <MenuItem onClick={() => handleDuplicateForm(onSubmit)}>
              Duplicate Form
            </MenuItem>
          )}
        </HTMLForm>
        {
          isFormCreator && (
            <HTMLForm action={deleteFormUrl} method="DELETE">
              {({ onSubmit }) => (
                <MenuItem onClick={() => handleDeleteForm(onSubmit)}>
                  Delete Form
                </MenuItem>
              )}
            </HTMLForm>
          )
        }
        {
          !isFormCreator && (
            <HTMLForm action={deleteCollaboratorAccessUrl} method="DELETE">
              {({ onSubmit }) => (
                <MenuItem onClick={() => handleDeleteCollaboratorAccess(onSubmit)}>
                  Remove Form
                </MenuItem>
              )}
            </HTMLForm>
          )
        }
        {
          publishSettings !== undefined && (
            [
              <Divider key="divider" />,
              <MenuItem key="toggleIsPublished" onClick={publishSettings.toggleIsPublished} disabled={publishSettings.isViewOnly}>
                {publishSettings.isPublished ? 'Unpublish' : 'Publish'}
              </MenuItem>
            ]
          )
        }
      </Menu>
    </div>
  );

  function handleDuplicateForm(onSubmit) {
    logGoogleAnalyticsEvent(GoogleAnalyticsEvent.DuplicateForm);
    onSubmit();
  }

  function handleDeleteForm(onSubmit) {
    if (!window.confirm('Are you sure?')) return;

    logGoogleAnalyticsEvent(GoogleAnalyticsEvent.DeleteForm);
    onSubmit();
  }

  function handleDeleteCollaboratorAccess(onSubmit) {
    if (!window.confirm('Are you sure?')) return;

    onSubmit();
  }
}
