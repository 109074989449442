import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../../../../utils/equality';
import escapeHtml from '../../../../../utils/escape_html';

export interface RichTextEditorProps {
  elementID ?: string;
  className?: string;
  value: string;
  readOnly?: boolean;
  metadataHeaders: Array<string>;
  onFocus?: IAllProps['onFocus'] & IAllProps['onInit'];
  onChange?: (content: string) => void;
  configs?: string;
}

const defaultConfigs = 'bold italic underline | indentgroup | metadataButton | undo redo removeformat';

export default function RichTextEditor(props: RichTextEditorProps) {
  const { elementID, className, readOnly, metadataHeaders, onFocus, onChange, configs } = props;

  const [initialValue, setInitialValue] = useState(props.value);

  // For some reason, if we give `value` directly to `<Editor>` and insert a metadata element,
  // the cursor appears before the newly inserted metadata element or sometimes the metadata
  // gets insert right at the start. Reading from a local state value here seems to resolve this
  // problem, which is possibly some rendering race-condition where it takes a long time for
  // `value` to be propagated down after a change has been made.
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setInitialValue(props.value);
    setValue(props.value);
  }, [elementID]);

  return (
    <div className={`rich-text-editor ${className}`}>
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        initialValue={initialValue}
        value={value}
        disabled={readOnly}
        inline
        // The editor is set to auto-focus on initialisation, but for some reason the editor is in a weird state
        // when the `onFocus` callback is called (e.g. `editor.hasFocus()` is false, and modifying the selection doesn't work).
        // Hence, we explicitly call `onFocus` again after it's fully initialised to ensure it works as expected.
        onInit={(event, editor) => {
          if (onFocus === undefined) return;

          onFocus(event, editor);
        }}
        onFocus={onFocus}
        onEditorChange={(newValue, _editor) => {
          setValue(newValue);
          onChange(newValue);
        }}
        init={{
          auto_focus: true,
          height: '100%',
          menubar: false,
          statusbar: false,
          plugins: 'paste lists autolink noneditable',
          toolbar: isEmpty(configs) ? defaultConfigs : configs,
          toolbar_groups: {
            indentgroup: {
              icon: 'unordered-list',
              tooltip: 'Formatting',
              items: 'bullist numlist outdent indent'
            }
          },
          custom_elements: '~allears-metadata',
          extended_valid_elements: 'allears-metadata[class]',

          setup(editor) {
            editor.ui.registry.addMenuButton('metadataButton', {
              icon: 'user',
              tooltip: 'Personalised Fields',
              fetch(callback) {
                if (metadataHeaders.length === 0) {
                  callback([{
                    type: 'menuitem' as const,
                    text: '<Add fields under the Audience tab first>'
                  }]);

                  return;
                }

                const items = metadataHeaders.map(h => ({
                  type: 'menuitem' as const,
                  text: h,
                  onAction() {
                    const html = `<allears-metadata class="mceNonEditable">@${escapeHtml(h)}</allears-metadata> `;
                    editor.execCommand('mceInsertContent', false, html);
                  }
                }));

                callback(items);
              }
            });
          }
        }}
      />
    </div>
  );
}
