import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import classNames from 'classnames';
import { selectTargetOnFocusIfValueEquals } from 'components/utils/select_element_on_focus';
import { DEFAULT_IMAGE_TEXT } from 'components/utils/default_text';
import { DisplayConditionEditor } from '../display_condition/DisplayConditionEditor';
import { ElementEditorProps, renderHeader } from '../BaseElementEditor';
import { isNotEmpty } from '../../utils/equality';
import { ImageElement } from './ImageElement';
import ImageUpload from '../../../../assets/images/form_elements/image_upload.svg';
import { getCsrfToken } from '../../utils/csrf';

enum UploadStatus {
  NoUpload = 0,
  Uploading,
  Uploaded,
}

interface UploadState {
  uploadStatus: UploadStatus;
  uploadError: string;
}

export function ImageElementEditor(props: ElementEditorProps<ImageElement>) {
  const { element, errors, viewOnly, updateFormImageUrl, onUpdate, onClone, onDelete } = props;
  const [csrfTokenState, setCsrfTokenState] = useState(getCsrfToken());
  const [uploadState, setUploadState] = useState({} as UploadState);

  useEffect(() => {
    setUploadState({ uploadStatus: UploadStatus.NoUpload, uploadError: '' });
  }, [element.id]);

  return (
    <article className="element-settings-element element-settings-image-element">
      {renderHeader(element, 'Image', viewOnly, onClone, onDelete, true)}

      <main className="element-content">
        {
          uploadState.uploadStatus === UploadStatus.Uploaded &&
          <Alert severity="success" onClose={() => setUploadState({ uploadStatus: UploadStatus.NoUpload, uploadError: '' })}>Uploaded Successfully!</Alert>
        }
        {uploadState.uploadStatus === UploadStatus.Uploading ? <LinearProgress /> : renderImageUploadContainer()}

        <TextField
          label="IMAGE CAPTION"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          multiline
          value={element.text}
          onFocus={selectTargetOnFocusIfValueEquals(DEFAULT_IMAGE_TEXT)}
          onChange={event => onUpdate({ ...element, text: event.target.value })}
          disabled={viewOnly}
        />

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function renderImageUploadContainer() {
    return (
      <Stack component={Paper} alignItems="center" justifyContent="center" className={classNames('image-upload-container', { error: isNotEmpty(uploadState.uploadError) || isNotEmpty(errors) })}>
        <img alt="" src={ImageUpload} />
        <label htmlFor="browse-link">
          <input
            hidden
            accept="image/png, image/jpeg"
            id="browse-link"
            type="file"
            onChange={event => handleUploadImage(event)}
            disabled={viewOnly}
          />
          <Link sx={{ cursor: 'pointer' }}>Browse to upload</Link>
        </label>
        {isNotEmpty(uploadState.uploadError) && <Typography variant="subtitle2" className="image-upload-size-error">{uploadState.uploadError}</Typography>}
        {errors?.image_url && <Typography variant="subtitle2" className="image-upload-size-error">{errors?.image_url[0]}</Typography>}
      </Stack>
    );
  }

  function handleUploadImage(event: React.ChangeEvent<HTMLInputElement>) {
    setUploadState({ uploadStatus: UploadStatus.Uploading, uploadError: '' });

    const formData = new FormData();
    formData.append('image', event.target.files[0]);
    formData.append('id', element.id);

    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfTokenState
      },
      body: formData
    };

    return fetch(updateFormImageUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          setUploadState({ uploadStatus: UploadStatus.NoUpload, uploadError: data.errors });
          return;
        }

        if (data) {
          setCsrfTokenState(data.csrf_token);
          setUploadState({ uploadStatus: UploadStatus.Uploaded, uploadError: '' });
          onUpdate({ ...element, image_url: data.image_url });
        }
      })
      .catch(error => {
        setUploadState({ uploadStatus: UploadStatus.NoUpload, uploadError: 'Upload failed!' });
        console.log(error);
      });
  }
}
