import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { SaveStatus } from './ContentContainer';
import { AudienceType, FormErrors, StaffAudienceProps, StudentAudienceProps, School, AudienceProps } from '../../../../models/FormProps';
import AudienceTypeSelector from './AudienceTypeSelector';
import AudienceSelector from './audience_selector/AudienceSelector';
import BreadcrumbHeader from './BreadcrumbHeader';
import PersonaliseAudienceContainer from './personalise_audience/PersonaliseAudienceContainer';
import { getCsrfToken } from '../../../../utils/csrf';
import FormVersionSnackBar from '../FormVersionSnackbar';
import TargetedUntargetedSelector from './targeted_untargeted_selector/TargetedUntargetedSelector';
import { isEmpty } from '../../../../utils/equality';

interface Props {
  school: School;
  viewableAudience: AudienceProps;
  saveStatus: SaveStatus;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
  changeAudienceCallback: (updatedAudience: AudienceProps) => void;
  personalisedFieldsTableUrl: string;
  clearPersonalisedFieldsUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  formsUrl: string;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  getStaffRosterUrl: string;
  formErrors: FormErrors;
  viewOnly: boolean;
  isHQUser: boolean;
  formHasResponses: boolean;
  enableTeachingGroups: boolean;
}

export enum AudienceStage {
  SelectAudienceType = 0,
  SelectedTargetedOrUntargetedAudience = 1,
  SelectIndividualsOrGroups = 2,
  PersonaliseFields = 3
}

export default function Content(props: Props): React.ReactElement {
  const {
    school,
    viewableAudience,
    saveStatus,
    setSaveStatusCallback,
    changeAudienceCallback,
    personalisedFieldsTableUrl,
    clearPersonalisedFieldsUrl,
    downloadPersonalisedFieldsUrl,
    uploadPersonalisedFieldsUrl,
    formsUrl,
    getSchoolsUrl,
    getStudentRosterUrl,
    getStaffRosterUrl,
    formErrors,
    viewOnly,
    isHQUser,
    formHasResponses,
    enableTeachingGroups
  } = props;

  const [viewableAudienceState, setViewableAudienceState] = useState(viewableAudience);
  const [activeStep, setActiveStep] = useState(initialiseActiveStep());
  const [maxStep, setMaxStep] = useState(initialiseMaxStep());
  const [csrfToken, setCsrfToken] = useState<string>(getCsrfToken());
  const [metadataVersion, setMetadataVersion] = useState<number>();
  const [isFormVersionSnackbarOpen, setIsFormVersionSnackbarOpen] = useState(false);
  const [personalisedFieldsErrors, setPersonalisedFieldsErrors] = useState<FormErrors>({});

  useEffect(() => {
    changeAudienceCallback(viewableAudienceState);
  }, [viewableAudienceState]);

  return (
    <main className="manage-forms-edit-page-content manage-forms-edit-audience-page-content">
      <BreadcrumbHeader
        formsUrl={formsUrl}
        activeStep={activeStep}
        saveStatus={saveStatus}
        viewableAudience={viewableAudienceState}
        setActiveStepCallback={setActiveStep}
        updateViewableAudienceCallback={updateViewableAudienceCallback}
        csrfToken={csrfToken}
        setCsrfToken={setCsrfToken}
        metadataVersion={metadataVersion}
        setMetadataVersion={setMetadataVersion}
        clearPersonalisedFieldsUrl={clearPersonalisedFieldsUrl}
        setIsFormVersionSnackbarOpen={setIsFormVersionSnackbarOpen}
        setPersonalisedFieldsErrors={setPersonalisedFieldsErrors}
        setSaveStatusCallback={setSaveStatusCallback}
        formErrors={formErrors}
        viewOnly={viewOnly}
      />
      <Stack>
        {renderStepper()}
        {renderStepperContent()}
      </Stack>
      <FormVersionSnackBar
        isSnackbarOpen={isFormVersionSnackbarOpen}
        setIsSnackbarOpenCallback={setIsFormVersionSnackbarOpen}
      />
    </main>
  );

  function renderStepper() {
    const steps = ['Select your audience type', 'Access type', 'Add individuals or groups', 'Add fields to personalise'];
    const stepsToRender = steps.slice(0, maxStep + 1);
    const audienceType = viewableAudienceState.type;

    return (
      <Stepper nonLinear activeStep={activeStep} className="audience-editor-stepper">
        {stepsToRender.map((label, index) => (
          <Step key={label} completed={activeStep > index}>
            <StepLabel
              onClick={() => handleOnClickStepper(index)}
              classes={{
                label: audienceType !== AudienceType.PUBLIC ? 'audience-editor-stepper-label' : 'audience-editor-stepper-label-disabled',
                active: 'audience-editor-stepper-label-active'
              }}
              StepIconProps={{
                classes: {
                  completed: 'audience-editor-stepper-completed-icon',
                  active: 'audience-editor-stepper-active-icon'
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  function renderStepperContent() {
    switch (activeStep) {
      case AudienceStage.SelectAudienceType:
        return (
          <AudienceTypeSelector
            viewableAudience={viewableAudienceState}
            viewOnly={viewOnly}
            setActiveStepCallback={setActiveStep}
            setMaxStepCallback={setMaxStep}
            updateViewableAudienceCallback={updateViewableAudienceCallback}
            formHasResponses={formHasResponses}
          />
        );
      case AudienceStage.SelectedTargetedOrUntargetedAudience:
        return (
          <TargetedUntargetedSelector
            viewableAudience={viewableAudienceState as StaffAudienceProps | StudentAudienceProps}
            viewOnly={viewOnly}
            setActiveStepCallback={setActiveStep}
            setMaxStepCallback={setMaxStep}
            updateViewableAudienceCallback={updateViewableAudienceCallback}
          />
        );
      case AudienceStage.SelectIndividualsOrGroups:
        return (
          <AudienceSelector
            school={school}
            viewableAudience={viewableAudienceState as StaffAudienceProps | StudentAudienceProps}
            updateViewableAudienceCallback={updateViewableAudienceCallback}
            setMaxStepCallback={setMaxStep}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
            getSchoolsUrl={getSchoolsUrl}
            getStudentRosterUrl={getStudentRosterUrl}
            getStaffRosterUrl={getStaffRosterUrl}
            enableTeachingGroups={enableTeachingGroups}
          />
        );
      case AudienceStage.PersonaliseFields:
        return (
          <PersonaliseAudienceContainer
            viewableAudience={viewableAudienceState as StaffAudienceProps | StudentAudienceProps}
            personalisedFieldsTableUrl={personalisedFieldsTableUrl}
            downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
            uploadPersonalisedFieldsUrl={uploadPersonalisedFieldsUrl}
            csrfToken={csrfToken}
            setCsrfToken={setCsrfToken}
            metadataVersion={metadataVersion}
            setMetadataVersion={setMetadataVersion}
            setIsFormVersionSnackbarOpen={setIsFormVersionSnackbarOpen}
            personalisedFieldsErrors={personalisedFieldsErrors}
            setPersonalisedFieldsErrors={setPersonalisedFieldsErrors}
            viewOnly={viewOnly}
            saveStatus={saveStatus}
            setSaveStatusCallback={setSaveStatusCallback}
          />
        );
      default:
        return null;
    }
  }

  function updateViewableAudienceCallback(updated: AudienceProps) {
    setViewableAudienceState(updated);
  }

  function handleOnClickStepper(index: number) {
    if (saveStatus !== SaveStatus.Saving) {
      setActiveStep(index);
    }
  }

  function initialiseActiveStep() {
    if (viewableAudience.type === AudienceType.PUBLIC) {
      return AudienceStage.SelectAudienceType;
    }

    if (viewableAudience.type === AudienceType.UNTARGETED_STAFF || viewableAudience.type === AudienceType.UNTARGETED_STUDENT || isEmpty(viewableAudience.entities)) {
      return AudienceStage.SelectedTargetedOrUntargetedAudience;
    }

    return AudienceStage.SelectIndividualsOrGroups;
  }

  function initialiseMaxStep() {
    if (viewableAudience.type === AudienceType.PUBLIC) {
      return AudienceStage.SelectAudienceType;
    }

    if (viewableAudience.type === AudienceType.UNTARGETED_STAFF || viewableAudience.type === AudienceType.UNTARGETED_STUDENT || isEmpty(viewableAudience.entities)) {
      return AudienceStage.SelectedTargetedOrUntargetedAudience;
    }

    return AudienceStage.PersonaliseFields;
  }
}
