import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import PersonaliseInstructionsImageOne from '../../../../../../../assets/images/personalise_instructions/personalise-instructions-1.png';
import PersonaliseInstructionsImageTwo from '../../../../../../../assets/images/personalise_instructions/personalise-instructions-2.png';
import PersonaliseInstructionsImageThree from '../../../../../../../assets/images/personalise_instructions/personalise-instructions-3.png';

const images: Array<{src: string; alt: string;}> = [
  { src: `${PersonaliseInstructionsImageOne}`, alt: 'Screenshots showing how personalised information can be used in forms' },
  { src: `${PersonaliseInstructionsImageTwo}`, alt: 'Screenshot of the page to add personalised information' },
  { src: `${PersonaliseInstructionsImageThree}`, alt: 'Screenshot of a spreadsheet containing personalised information' }
];
const maxSteps = images.length;

export default function DialogInstructions() {
  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const image = images[activeStep];

  return (
    <>
      <Link href="#" underline="hover" className="link" variant="subtitle2" onClick={() => setOpenDialog(true)}>
        (What is this?)
      </Link>
      <Modal
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <Stack direction="row" className="dialog-container personalised-fields-dialog-container">
          <Stack justifyContent="space-between" className="dialog-container-mobile-stepper">
            <img alt={image.alt} src={image.src} className="dialog-container-image" />
            <MobileStepper
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={(
                <Button
                  size="small"
                  onClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              )}
              backButton={(
                <Button
                  size="small"
                  onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              )}
            />
          </Stack>
          <Box className="dialog-container-instructions">
            <Stack p={2} spacing={2}>
              <Typography variant="h5" className="dialog-container-header-typography">
                Introducing Form Personalisation
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                In this section, add information specific to each student i.e. gender, CCA, age etc. You may assign various questions in the form to specific student(s) based on these added information.
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                For instance, you may choose to assign questions with CCA options like Girl Guides to female students only.
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                How to set up?
              </Typography>

              <Typography component="ol">
                <Typography variant="body2" component="li" className="dialog-container-body-typography">
                  Download a template with pre-filled index number, name and class of students you have selected as audience.
                </Typography>
                <Typography variant="body2" component="li" className="dialog-container-body-typography">
                  Add additional columns of each new information category i.e. gender, CCA, age, etc.
                </Typography>
                <Typography variant="body2" component="li" className="dialog-container-body-typography">
                  Upload the filled template.
                </Typography>
                <Typography variant="body2" component="li" className="dialog-container-body-typography">
                  Apply <strong>"Display Condition"</strong> to your choice of questions to specific student(s).
                </Typography>
              </Typography>

              <Typography variant="body2" className="dialog-container-body-typography">
                If the uploaded file contains an error, the system will highlight an error in the corresponding field and an error message will be displayed. Please download the file to make the necessary changes as flagged by the system.
              </Typography>

              <Button
                variant="contained"
                onClick={() => setOpenDialog(false)}
                className="dialog-container-close-button"
              >
                Close
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Modal>
    </>
  );
}
