import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import { DEFAULT_INSTRUCTIONS } from 'components/utils/default_text';
import { selectEditorBodyOnFocusIfContentEquals } from 'components/utils/select_element_on_focus';
import React from 'react';
import RichTextEditor from '../../pages/manage/forms/edit_questions/editor/RichTextEditor';
import { isNotEmpty } from '../../utils/equality';
import { sanitizeHtmlInString } from '../../utils/html';

interface InstructionsEditorProps {
  instructions: string;
  errors?: Array<string>;
  metadataHeaders: Array<string>;
  onChangeInstructions: (instructions: string) => void;
  viewOnly: boolean;
}

export function InstructionsEditor(props: InstructionsEditorProps) {
  const { instructions, errors, metadataHeaders, onChangeInstructions, viewOnly } = props;

  const hasErrors = isNotEmpty(errors);
  const sanitized = sanitizeHtmlInString(instructions);

  return (
    <article className="element-settings-element element-settings-instructions-element">
      <header className="element-title">Form Instructions</header>

      <main className="element-content">
        <FormControl error={hasErrors}>
          <RichTextEditor
            className={classNames('instructions', { error: hasErrors })}
            value={sanitized}
            readOnly={viewOnly}
            metadataHeaders={metadataHeaders}
            onFocus={selectEditorBodyOnFocusIfContentEquals(DEFAULT_INSTRUCTIONS)}
            onChange={value => onChangeInstructions(value)}
          />
          {hasErrors && <FormHelperText>{errors[0]}</FormHelperText>}
        </FormControl>
      </main>
    </article>
  );
}
