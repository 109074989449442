import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { ElementEditorProps, renderHeader } from '../../BaseElementEditor';
import { DisplayConditionEditor } from '../../display_condition/DisplayConditionEditor';
import { renderAnswerSettingLabel, renderQuestionText, renderRequiredSetting } from '../BaseQuestionEditor';
import { OptionsEditor } from './option/OptionsEditor';
import { MultipleChoiceQuestion, MultipleChoiceQuestionOption } from './MultipleChoiceQuestion';

export function MultipleChoiceQuestionEditor(props: ElementEditorProps<MultipleChoiceQuestion>) {
  const { element, errors, viewOnly, onUpdate, onClone, onDelete, metadata } = props;

  return (
    <article className="element-settings-element element-settings-multiple-choice-question">
      {renderHeader(element, 'Multiple Choice', viewOnly, onClone, onDelete)}

      <main className="element-content">
        {renderAnswerSettingLabel()}
        {renderRequiredSetting(element, viewOnly, onUpdate)}
        {renderDropdownCheckbox()}

        {renderQuestionText(element, errors, viewOnly, onUpdate, metadata.headers)}

        <OptionsEditor
          options={element.options}
          onOptionsChangeCallback={onOptionsChange}
          onOptionsAndViewRemainingOptionsChangeCallback={onOptionsAndViewRemainingOptionsChange}
          errors={errors?.options}
          viewOnly={viewOnly}
          viewRemainingOptions={element.view_remaining_options}
        />

        <DisplayConditionEditor {...props} />
      </main>
    </article>
  );

  function renderDropdownCheckbox() {
    return (
      <FormControlLabel
        control={<Checkbox checked={element.is_dropdown} onChange={onChangeDropdown} />}
        label="Display options as a dropdown"
        disabled={viewOnly}
      />
    );
  }

  function onChangeDropdown(event: React.ChangeEvent<HTMLInputElement>) {
    onUpdate({ ...element, is_dropdown: event.target.checked });
  }

  function onOptionsChange(newOptions: Array<MultipleChoiceQuestionOption>) {
    onUpdate({ ...element, options: newOptions });
  }

  function onOptionsAndViewRemainingOptionsChange(newOptions: Array<MultipleChoiceQuestionOption>, viewRemainingOptions: boolean) {
    onUpdate({ ...element, view_remaining_options: viewRemainingOptions, options: newOptions });
  }
}
