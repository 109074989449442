import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  userID: string;
  aeFeedbackFormUrl: string;
}

export default function FeedbackFormFab(props: Props): React.ReactElement {
  const { userID, aeFeedbackFormUrl } = props;
  const key = `${userID}_ae_feedback_form`;
  const [displayFab, setDisplayFab] = useState(shouldDisplayFab());

  return (
    displayFab && (
      <Fab
        sx={{ bottom: '16px', position: 'fixed', right: '16px', textTransform: 'none' }}
        color="primary"
        aria-label="feedback-form"
        href={aeFeedbackFormUrl}
        target="_blank"
        variant="extended"
        onClick={handleOnClick}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body1">
            Help us improve
          </Typography>
          <OpenInNewIcon />
        </Stack>
      </Fab>
    )
  );

  function shouldDisplayFab() {
    return sessionStorage.getItem(key) !== 'invisible';
  }

  function handleOnClick() {
    sessionStorage.setItem(key, 'invisible');
    setDisplayFab(false);
  }
}
