import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';

const paletteTheme = createTheme({
  palette: {
    primary: {
      light: '#3387FB',
      main: '#0054C8'
    },
    background: {
      default: '#F5F7FA'
    }
  }
});

const componentsThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          backgroundColor: paletteTheme.palette.common.white
        }
      }
    }
  }
};

const defaultTheme = createTheme(
  paletteTheme,
  componentsThemeOptions
);

export default defaultTheme;
