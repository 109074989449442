import React from 'react';
import classNames from 'classnames';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { isNotEmpty } from '../../utils/equality';
import { modifyAnchorTagBehaviour, sanitizeHtmlInString } from '../../utils/html';

export const InstructionsID = 'instructions';

interface InstructionsProps {
  instructions: string;
  editMode?: {
    errors?: Array<string>;
    isSelected: boolean;
    selectedElementRefCallback: (node: HTMLElement) => void;
    onSelectElement: (elementID: string) => void;
  };
}

export function Instructions(props: InstructionsProps) {
  const { instructions, editMode } = props;

  const className = classNames('form-element form-instructions-element selectable', { selected: editMode?.isSelected });

  const sanitizedInstructions = sanitizeHtmlInString(instructions);
  const modifiedInstructions = modifyAnchorTagBehaviour(sanitizedInstructions);

  return (
    <article className={className} onClick={() => editMode?.onSelectElement(InstructionsID)} ref={editMode?.isSelected ? editMode?.selectedElementRefCallback : null}>
      <header className="element-header">
        <Typography>FORM INSTRUCTIONS</Typography>
        {isNotEmpty(editMode?.errors) && <Alert className="errors" severity="error">{editMode?.errors.length}</Alert>}
      </header>

      {/* eslint-disable-next-line react/no-danger -- HTML string is sanitised. */}
      <p dangerouslySetInnerHTML={{ __html: modifiedInstructions }} />
    </article>
  );
}
