import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { isEmpty, isNotEmpty } from 'components/utils/equality';
import { AudienceType, Clazz, Level, School, Student, StudentRoster, StudentAudienceProps } from 'components/models/FormProps';
import SchoolSelector from '../SchoolSelector';
import AudienceGroupSelector from './AudienceGroupSelector';

interface Props {
  school: School;
  schools: Array<School>;
  viewableAudience: StudentAudienceProps;
  addViewableEntityCallback: (viewableEntity: School | Level | Clazz | Student) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  rosterUrl: string;
}

export default function StudentAudienceSelector(props: Props): React.ReactElement {
  const { school, schools, viewableAudience, addViewableEntityCallback, viewOnly, isHQUser, rosterUrl } = props;
  const [selectedSchoolID, setSelectedSchoolID] = useState<number>(school?.entity_id);
  const [studentRosters, setStudentRosters] = useState<Array<StudentRoster>>([]);

  useEffect(() => {
    if (isNotEmpty(selectedSchoolID)) getRostersBySchoolID();
  }, [selectedSchoolID]);

  return (
    <Stack spacing={2} component={Paper} className="audience-editor-audience-selector">
      {isEmpty(selectedSchoolID) && isHQUser ? renderSchoolsSelector() : renderAudienceGroupSelector()}
    </Stack>
  );

  function renderSchoolsSelector() {
    if (isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <SchoolSelector
        schools={schools}
        viewOnly={viewOnly}
        setSchoolIDCallback={setSelectedSchoolID}
      />
    );
  }

  function renderAudienceGroupSelector() {
    if (isEmpty(studentRosters)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <AudienceGroupSelector
        viewableAudience={viewableAudience}
        studentRosters={studentRosters}
        isHQUser={isHQUser}
        addViewableEntityCallback={addViewableEntityCallback}
        setSchoolIDCallback={setSelectedSchoolID}
        viewOnly={viewOnly}
      />
    );
  }

  function getRostersBySchoolID() {
    const url = new URL(rosterUrl);
    url.search = new URLSearchParams({ school_id: `${selectedSchoolID}`, type: AudienceType.STUDENT }).toString();

    const requestOptions = {
      method: 'GET'
    };

    return fetch(url.toString(), requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setStudentRosters(data.student_roster);
        }
      });
  }
}
