import React, { useRef, useState } from 'react';
import usePromptWindowUnload, { removePromptWindowUnload } from '../../../../utils/prompt_window_unload';
import Content from './Content';
import { getCsrfToken } from '../../../../utils/csrf';
import { useDidUpdateEffect } from '../CustomHooks';
import { Staff, Collaborator } from './Page';

interface Props {
  creatorID: number;
  schoolID: number | null;
  schoolName: string | null;
  collaborators: Array<Collaborator>;
  staffs: Array<Staff>;
  formsUrl: string;
  searchUrl: string;
  updateUrl: string;
  viewOnly: boolean;
}

export enum SaveStatus {
  Saved = 0,
  Saving,
  Error
}

export default function ContentContainer(props: Props) {
  const { collaborators, updateUrl, viewOnly } = props;

  const saveTimerRef = useRef<number>();

  const [collaboratorsState, setCollaboratorsState] = useState(collaborators);
  const [csrfTokenState, setCsrfTokenState] = useState(getCsrfToken());
  const [saveStatus, setSaveStatus] = useState(SaveStatus.Saved);

  if (!viewOnly) useDidUpdateEffect(saveWithDelay, [collaboratorsState]);
  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  return (
    <Content {...props} saveStatus={saveStatus} onChangeCollaborators={onChangeCollaborators} />
  );

  function onChangeCollaborators(collaborators: Array<Collaborator>) {
    setCollaboratorsState(collaborators);
  }

  function saveWithDelay() {
    setSaveStatus(SaveStatus.Saving);

    clearTimeout(saveTimerRef.current);
    saveTimerRef.current = window.setTimeout(save, 1000);

    return function cleanup() {
      clearTimeout(saveTimerRef.current);
    };
  }

  function save() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfTokenState
      },
      body: JSON.stringify({
        form_collaborators: collaboratorsState
      })
    };

    return fetch(updateUrl, requestOptions)
      .then(response => {
        if (response.redirected) {
          removePromptWindowUnload();
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setCsrfTokenState(data.csrf_token);
          setSaveStatus(data.success ? SaveStatus.Saved : SaveStatus.Error);
        }
      })
      .catch(e => {
        console.log(e);
        setSaveStatus(SaveStatus.Error);
      });
  }
}
