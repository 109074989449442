import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { isEmpty } from 'components/utils/equality';
import FormProps, { FormErrors, PublishStatusProps } from 'components/models/FormProps';
import AudienceMetadata from 'components/pages/sna/models/AudienceMetadata';
import Form from './form/Form';
import { SaveStatus } from './ContentContainer';
import { ShareModal } from './ShareModal';

interface Props {
  form: FormProps
  formErrors: FormErrors;
  saveStatus: SaveStatus;
  metadata: AudienceMetadata;
  previewUrl: string;
  formUrl: string;
  formsUrl: string;
  createAnnouncementOnPgUrl: string;
  onChangeForm: (form: FormProps) => void;
  viewOnly: boolean;
  isShareableToPG: boolean;
}

export default function Content(props: Props) {
  const { form, formErrors, saveStatus, metadata, previewUrl, formUrl, formsUrl, createAnnouncementOnPgUrl, onChangeForm, viewOnly, isShareableToPG } = props;

  const [formState, setFormState] = useState(form);
  const [selectedElementID, setSelectedElementID] = useState<string>(null);
  const [showShareModal, setShowShareModal] = useState(false);

  useEffect(() => onChangeForm(formState), [formState]);

  return (
    <main className="manage-forms-edit-page-content sna-manage-forms-edit-questions-page-content">
      {renderHeader()}

      {showShareModal && (
        <ShareModal
          formName={formState.title}
          formUrl={formUrl}
          createAnnouncementOnPgUrl={createAnnouncementOnPgUrl}
          publishStatus={formState.state}
          enableEmailCopyOfResponse={formState.enable_email_copy_of_response}
          onChangePublishStatus={onChangePublishStatus}
          onChangeEnableEmailCopyOfResponse={onChangeEnableEmailCopyOfResponse}
          onCloseModal={() => setShowShareModal(false)}
          viewOnly={viewOnly}
          isShareableToPG={isShareableToPG}
        />
      )}

      <div className="form-editor">
        <Form
          form={formState}
          formErrors={formErrors}
          selectedElementID={selectedElementID}
          onSelectElement={setSelectedElementID}
          viewOnly={viewOnly}
          onChangeTitle={onChangeTitle}
          onChangeInstructions={onChangeInstructions}
          metadata={metadata}
        />
      </div>
    </main>
  );

  function renderHeader() {
    return (
      <header className="manage-forms-edit-page-content-header">
        <Breadcrumbs className="breadcrumbs">
          <Link underline="hover" color="inherit" href={formsUrl}>Home</Link>
          <Typography>Questions</Typography>
        </Breadcrumbs>

        {renderSaveStatus()}
        <Button variant="outlined" href={previewUrl} target="_blank">PREVIEW</Button>
        <Button variant="contained" onClick={() => setShowShareModal(true)}>SHARE</Button>
      </header>
    );
  }

  function renderSaveStatus() {
    if (viewOnly) return <Alert className="save-status" severity="warning">View Only</Alert>;

    switch (saveStatus) {
      case SaveStatus.Saved: {
        return <Alert className="save-status" severity="success">Saved</Alert>;
      }
      case SaveStatus.Saving: {
        return <Alert className="save-status" severity="info">Saving...</Alert>;
      }
      case SaveStatus.Error: {
        const defaultMsg = 'Please fix error(s) to save';
        return <Alert className="save-status" severity="error">{isEmpty(formErrors.version) ? defaultMsg : formErrors.version}</Alert>;
      }
      default: {
        return null;
      }
    }
  }

  function onChangePublishStatus(status: PublishStatusProps) {
    setFormState(prevState => ({ ...prevState, state: status }));
  }

  function onChangeEnableEmailCopyOfResponse(enableEmailCopyOfResponse: boolean) {
    setFormState(prevState => ({ ...prevState, enable_email_copy_of_response: enableEmailCopyOfResponse }));
  }

  function onChangeTitle(title: string) {
    setFormState(prevState => ({ ...prevState, title }));
  }

  function onChangeInstructions(instructions: string) {
    setFormState(prevState => ({ ...prevState, instructions }));
  }
}
