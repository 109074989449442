import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Cookies from 'js-cookie';
import React, { useState } from 'react';

/**
 * Cookie keys for each feature, to ensure no accidental reuse of the same keys over time.
 */
export enum FeatureKey {
  HomePageV1 = 'v1',
  HomePageV2 = 'v2',
  HomePageV3 = 'v3',
  ParentsGatewayIntegration = 'v4',
  ConnectogramLaunch = 'v5',
  StudentMimsOnlyLogin = 'v6',

  FlexiListIntegration = 'flexilist_v1'
}

interface Props {
  userID: string;
  featureKey: FeatureKey;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>;
  children: (props: { onDialogClose: () => void }) => JSX.Element | Array<JSX.Element>;
}

const getCookieKey = (userID: string, featureKey: FeatureKey): string => `${userID}_${featureKey}`;

/**
 * A dialog to introduce a new feature. The dialog will be shown to each user only once, on the first time that the user accesses the target page.
 *
 * @param children - Dialog content. Should contain elements with IDs `dialog-title` and `dialog-description` respectively.
 *
 * @example
 * <FeatureIntroDialog userID={1} featureKey={FeatureKey.FlexiListV1}>
 *   <p id="dialog-title">My Title</p>
 *   <p id="dialog-description">Some description</p>
 * </FeatureIntroDialog>
 */
export default function FeatureIntroDialog(props: Props): React.ReactElement {
  const { userID, featureKey, dialogProps = {}, children } = props;

  const cookieKey = getCookieKey(userID, featureKey);
  const [openDialog, setOpenDialog] = useState(shouldDisplay());

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...dialogProps}
      open={openDialog}
      onClose={handleClose}
    >
      <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '0', right: '0' }}>
        <CloseIcon />
      </IconButton>

      {children({ onDialogClose: handleClose })}
    </Dialog>
  );

  function shouldDisplay() {
    return Cookies.get(cookieKey) !== 'invisible';
  }

  function handleClose() {
    setOpenDialog(false);
    Cookies.set(cookieKey, 'invisible', { expires: generateOneYearExpiryDate(), secure: true });
  }

  function generateOneYearExpiryDate() {
    const today = new Date();
    return new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
  }
}
